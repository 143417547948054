import {
  ADD_USER_REQUEST,
  DELETE_USER_REQUEST,
  GET_ALL_USERS_REQUEST,
  GET_USER_REQUEST,
  UPDATE_USER_REQUEST,
} from "../../constants/ActionTypes";

export const getAllUsers = (user) => {
  return { type: GET_ALL_USERS_REQUEST, payload: user || "", loading: true };
};

export const getUser = () => {
  return { type: GET_USER_REQUEST };
};

export const addUser = (data) => {
  return { type: ADD_USER_REQUEST, payload: data };
};

export const updateUser = () => {
  return { type: UPDATE_USER_REQUEST };
};

export const deleteUser = () => {
  return { type: DELETE_USER_REQUEST };
};
