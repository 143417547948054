import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import "./style.css";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import {
  CreditCardOutlined,
  ExportOutlined,
  PartitionOutlined,
} from "@ant-design/icons";
import ActiveDevices from "../../assets/Images/ActiveDevice.svg";

const SubMenu = Menu.SubMenu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const selectedKeys = pathname.substr(1);

  const [defaultOpenKeys, setdefaultOpenKeys] = useState(
    selectedKeys.split("/")[0]
  );
  const { role } = localStorage.user ? JSON.parse(localStorage.user) : "";

  const selectedItem = [
    "dashboard",
    "assets",
    "card-type",
    "card",
    "sub-card-type",
    "assign-card",
    "configure-card",
    "device-config",
  ].includes(selectedKeys.split("/")[0])
    ? selectedKeys.split("/")[0]
    : selectedKeys.split("/")[1] || "";

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  return (
    <div
      onMouseOver={() => {
        setSidebarCollapsed(false);
      }}
      onMouseLeave={() => {
        setSidebarCollapsed(true);
      }}
    >
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content gx-mt-4">
        <CustomScrollbars className="gx-layout-sider-scrollbar sidebar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedItem]}
            defaultSelectedKeys={["dashboard"]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            onClick={(e) => {
              setdefaultOpenKeys(e?.keyPath[1]);
              // if (
              //   e.key !== "role-permission-table" &&
              //   e.key !== "role-listings" &&
              //   e.key !== "template-listing" &&
              //   e.key !== "manage-template"
              // ) {
              //   setOpenKeys([]);
              // }
            }}
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <i className="icon icon-widgets" />
                <span>
                  <IntlMessages id="sidebar.dashboard" />
                </span>
              </Link>
            </Menu.Item>
            {(role === "superAdmin" || role === "admin") && (
              <Menu.Item
                key={
                  selectedItem === "add-company"
                    ? "add-company"
                    : selectedItem === "edit-company"
                    ? "edit-company"
                    : "company-listing"
                }
              >
                <Link to="/company/company-listing">
                  <i className="icon icon-home" />
                  <span>
                    <IntlMessages id="sidebar.companies" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item
              key={
                selectedItem === "add-stations"
                  ? "add-stations"
                  : selectedItem === "edit-stations"
                  ? "edit-stations"
                  : selectedItem === "stations-details"
                  ? "stations-details"
                  : "stations-listing"
              }
            >
              <Link to="/stations/stations-listing">
                <i className="icon icon-editor" />
                <span>
                  <IntlMessages id="sidebar.projects" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={
                selectedItem === "add-location"
                  ? "add-location"
                  : selectedItem === "edit-location"
                  ? "edit-location"
                  : selectedItem === "location-details"
                  ? "location-details"
                  : "location-listing"
              }
            >
              <Link to="/location/location-listing">
                <i className="icon icon-widgets" />
                <span>Location</span>
              </Link>
            </Menu.Item>
            {(role === "superAdmin" || role === "admin") && (
              <>
                <Menu.Item key={"assets"}>
                  <Link to="/assets">
                    <i className="icon icon-editor" />
                    <span>Assets</span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key={
                    selectedItem === "add-user"
                      ? "add-user"
                      : selectedItem === "edit-user"
                      ? "edit-user"
                      : selectedItem === "user-details"
                      ? "user-details"
                      : "user-listing"
                  }
                >
                  <Link to="/user/user-listing">
                    <i className="icon icon-avatar" />
                    <span>
                      <IntlMessages id="sidebar.users" />
                    </span>
                  </Link>
                </Menu.Item>
              </>
            )}
            <Menu.Item
              key={
                selectedItem === "maintenance-details"
                  ? "maintenance-details"
                  : selectedItem === "add-maintenance"
                  ? "add-maintenance"
                  : "maintenance-listing"
              }
            >
              <Link to="/maintenance/maintenance-listing">
                <i className="icon icon-editor" />
                <span>Maintenance</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={
                selectedItem === "alert-warning-listing" &&
                "alert-warning-listing"
              }
            >
              <Link to="/alert-warning/alert-warning-listing">
                <i className="icon icon-widgets" />
                <span>Alert Warning</span>
              </Link>
            </Menu.Item>
            {role === "superAdmin" && (
              <>
                <Menu.Item
                  key={selectedItem === "active-devices" && "active-devices"}
                >
                  <Link to="/device/active-devices">
                    {/* <span className="gx-m-0 gx-mr-3">
                      <img src={ActiveDevices} alt="Active" />
                    </span> */}
                    <i className="icon icon-circle" />
                    <span>Active Devices</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="card-type">
                  <Link to="/card-type/listing">
                    <i className="icon icon-widgets" />
                    <span>Card Type</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="sub-card-type">
                  <Link to="/sub-card-type/listing">
                    <i className="icon icon-widgets" />
                    <span>Sub Card Type</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="card">
                  <Link to="/card/listing">
                    <i className="icon icon-widgets" />
                    <span>Card</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="assign-card">
                  <Link to="/assign-card">
                    <i className="icon icon-widgets" />
                    <span>Assign Card</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="configure-card">
                  <Link to="/configure-card">
                    <i className="icon icon-widgets" />
                    <span>Configure Card</span>
                  </Link>
                </Menu.Item>
              </>
            )}
            {console.log("selectedItem", selectedItem)}
            <Menu.Item
              key={
                selectedItem === "details" ? "details" : "notification-listing"
              }
            >
              <Link to="/notification/notification-listing">
                <i className="icon icon-widgets" />
                <span>Notification</span>
              </Link>
            </Menu.Item>
            {role !== "maintainer" && (
              <SubMenu
                key="settings"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-components" />
                    <span>
                      <IntlMessages id="sidebar.settings" />
                    </span>
                  </span>
                }
              >
                {(role === "superAdmin" || role === "admin") && (
                  <>
                    <Menu.Item
                      key={
                        selectedItem === "add-device-type"
                          ? "add-device-type"
                          : selectedItem === "edit-device-type"
                          ? "edit-device-type"
                          : selectedItem === "device-type-details"
                          ? "device-type-details"
                          : "device-type-listing"
                      }
                    >
                      <Link to="/device-type/device-type-listing">
                        <i className="icon icon-etherium" />
                        <span>
                          <IntlMessages id="sidebar.deviceType" />
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={"export-company"}>
                      <Link to="/company/export-company">
                        <ExportOutlined className="gx-m-0 gx-mr-1" />
                        <span>
                          <IntlMessages id="sidebar.export" />
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="device-config">
                      <Link to="/device-config/subscribe">
                        <PartitionOutlined className="gx-m-0 gx-mr-1" />
                        <span>Device Config</span>
                      </Link>
                    </Menu.Item>
                    {/* <Menu.Item key={"sync-company"}>
                    <Link to="/company/sync-company">
                    <ExportOutlined className="gx-m-0 gx-mr-1" />
                    <span>
                    Sync 
                    </span>
                    </Link>
                  </Menu.Item> */}
                  </>
                )}
                {/* <Menu.Item key="others">
                <Link to="/settings/others">
                <i className="icon icon-widgets" />
                <span>
                <IntlMessages id="sidebar.others" />
                </span>
                </Link>
              </Menu.Item> */}
              </SubMenu>
            )}
          </Menu>
        </CustomScrollbars>
      </div>
    </div>
  );
};

export default React.memo(SidebarContent);
