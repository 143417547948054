import React, { useState } from "react";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import IntlMessages from "util/IntlMessages";
import OtpInput from "../components/OtpInput";
import { forgotOtp } from "../util/formError";
import { ForgotPasswordApi, SetPasswordApi } from "../appRedux/API/Auth";
import { isValidPassword } from "../util/common";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [erorMsg, setErrorMsg] = useState({});
  const [formValue, setFormValue] = useState();
  const [switchPage, setSwitchPage] = useState("forgot");

  const [otpValues, setOtpValues] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  

 
  const handleChange = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  const onFinishFailed = (errorInfo) => {};

  const onFinish = async (values) => {
    setLoading(true);
    if (switchPage === "reset") {
      const error = forgotOtp({ ...otpValues });
      setErrorMsg(error);
      if (Object.keys(error).length === 0) {
        const otpSet = `${otpValues?.otp1}${otpValues?.otp2}${otpValues?.otp3}${otpValues?.otp4}${otpValues?.otp5}${otpValues?.otp6}`;
        const response = await SetPasswordApi({
          email: formValue?.email || "",
          password: formValue?.confirmPassword,
          otp: otpSet,
        });
        if (response?.data?.status === 200) {
          setLoading(false);
          message.success(response?.data?.message.toString());
          history.push("signin");
        } else {
          setLoading(false);
          message.error(response?.data?.message.toString());
        }
      }
    }
    if (switchPage === "forgot") {
      const response = await ForgotPasswordApi({
        email: formValue?.email,
      });
      if (response?.data?.status === 200) {
        setLoading(false);
        message.success(response?.data?.message.toString());
        setSwitchPage("reset");
      } else {
        setLoading(false);
        message.error(response?.data?.message.toString());
      }
    }

    setLoading(false);
  };

  return (
    <div className="gx-app-login-wrap gx-bg-primary">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
            <img src={"/assets/images/logo-white.png"} alt="Neature" />

            <h2 className="title gx-mb-4 titleHead">
              {" "}
              {switchPage === "reset"
                ? "Create New Password"
                : "Forgot Password"}{" "}
            </h2>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0 "
            >
              <Row>
                <Col span={24}>
                  {switchPage === "reset" ? (
                    <>
                      <div className="gx-mb-3">
                        <Typography.Text className="typography">
                          Your OTP
                        </Typography.Text>
                        <OtpInput
                          setOtpValues={setOtpValues}
                          otpValues={otpValues}
                          error={erorMsg?.otp || ""}
                          setErrors={setErrorMsg}
                          erorMsg={erorMsg}
                          className="gx-mt-2"
                        />
                        {/* <div className="gx-d-flex gx-justify-content-between">
                          <Typography.Text
                            style={{ fontSize: "14px", color: "#697174" }}
                          >
                            Didn't receive OTP?{" "}
                            <span
                              onClick={() =>
                                UserInfo.handleReset(UserInfo.values)
                              }
                              style={{ color: "#053966" }}
                            >
                              Resend Code
                            </span>
                          </Typography.Text>
                          <FormItem>
                            <Button
                              loading={loading}
                              type="primary"
                              htmlType="submit"
                              className="gx-m-0"
                            >
                              Submit
                            </Button>
                          </FormItem>
                        </div> */}
                      </div>
                      <div className="grid gx-gap-3">
                        <Typography.Text className="typography">
                          New Password
                        </Typography.Text>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: (
                                <IntlMessages id="changePassword.newPassword" />
                              ),
                            },
                            () => ({
                              validator(_, value) {
                                if (!value || isValidPassword(value)) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  <div className="patternErr">
                                    <IntlMessages id="changePassword.pattern" />
                                  </div>
                                );
                              },
                            }),
                          ]}
                          className="w-full"
                          name="newPassword"
                        >
                          <Input.Password
                            type="password"
                            className="gx-mt-1 w-full"
                            name="newPassword"
                            onChange={handleChange}
                            iconRender={(visible) =>
                              visible ? (
                                <EyeInvisibleOutlined />
                              ) : (
                                <EyeTwoTone />
                              )
                            }
                            // style={{ minWidth: "320px" }}
                          />
                        </Form.Item>
                      </div>
                      <div className="grid gx-gap-3">
                        <Typography.Text className="typography">
                          Confirm Password
                        </Typography.Text>
                        <Form.Item
                          dependencies={["newPassword"]}
                          className="w-full"
                          hasFeedback
                          name="confirmPassword"
                          rules={[
                            {
                              required: true,
                              message: (
                                <IntlMessages id="changePassword.confirmPassword" />
                              ),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("newPassword") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  <IntlMessages id="changePassword.mismatch" />
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            type="password"
                            className="gx-mt-1 w-full inputWidth"
                            name="confirmPassword"
                            onChange={handleChange}
                            iconRender={(visible) =>
                              visible ? (
                                <EyeInvisibleOutlined />
                              ) : (
                                <EyeTwoTone />
                              )
                            }
                            // style={{ minWidth: "320px" }}
                          />
                        </Form.Item>
                      </div>
                    </>
                  ) : (
                    <div className="">
                      <Typography.Text className="typography">
                        Email
                      </Typography.Text>
                      <Form.Item
                        // initialValue="jarsservices709@gmail.com"
                        rules={[
                          {
                            required: true,
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                        name="email"
                      >
                        <Input
                          name="email"
                          value={formValue?.email}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  )}
                </Col>
              </Row>

              <Form.Item className="gx-text-center">
                <Button
                  loading={loading}
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
