import axiosInstance from "./axios";

const USER_API = "/api/user";
const UPLOAD_API = "/api/generic";
const PROFILE_API = "/api/profile";

export const getAllUsersAPI = async (param) => {
  try {
    const response = await axiosInstance.get(
      USER_API + `?${param?.payload || ""}`
    );
    return response;
  } catch (error) {
    return await error;
  }
};

export const getUserAPI = async () => {
  try {
    return await axiosInstance.get(USER_API);
  } catch (error) {
    return await error;
  }
};

export const getUserById = async (_id) => {
  try {
    const response = await axiosInstance.get(USER_API + `/${_id}`);
    return response;
  } catch (error) {
    return await error;
  }
};

export const addUserAPI = async (body) => {
  try {
    const response = await axiosInstance.post(USER_API, body);
    return response;
  } catch (error) {
    return await error;
  }
};

export const updateUserAPI = async (_id, body) => {
  try {
    return await axiosInstance.put(USER_API + `/${_id}`, body);
  } catch (error) {
    return await error;
  }
};

export const deleteUserAPI = async (_id) => {
  try {
    return await axiosInstance.delete(USER_API + `/${_id}`);
  } catch (error) {
    return await error;
  }
};

export const AddImageApi = async (body) => {
  try {
    return await axiosInstance.post(`${UPLOAD_API}/upload`, body);
  } catch (error) {
    return await error;
  }
};

export const DeleteImageApi = async (param) => {
  try {
    return await axiosInstance.delete(`${UPLOAD_API}/remove${param}`);
  } catch (error) {
    return await error;
  }
};



export const getProfileAPI = async () => {
  try {
    return await axiosInstance.get(PROFILE_API);
  } catch (error) {
    return await error;
  }
};

export const updateProfileAPI = async (body) => {
  try {
    return await axiosInstance.put(PROFILE_API, body);
  } catch (error) {
    return await error;
  }
};
