import axios from "axios";

export const getEntityAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const addEntityAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const updateEntityAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const deleteEntityAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};
