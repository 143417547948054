import { message } from "antd";
import axiosInstance from "./axios";

// export const getCompanyAPI = (companyId) => {
//   return axiosInstance.get(`/api/company/${companyId}`);
// };

// export const addCompanyAPI = (payload) => {
//   delete payload._id;
//   delete payload.isDeleted;
//   return axiosInstance.post(`/api/company`, payload);
// };

// export const updateCompanyAPI = (payload) => {
//   //TODO:need improvement. The delete key code need to move somewhere else
//   let companyId = payload._id;
//   delete payload._id;
//   delete payload.__v;
//   delete payload.isDeleted;
//   return axiosInstance.put(`/api/company/${companyId}`, payload);
// };

// export const deleteCompanyAPI = (companyId) => {
//   return axiosInstance.delete(`/api/company/${companyId}`);
// };

const COMPANY_API = "/api/company";

export const getAllCompaniesAPI = async (param) => {
  try {
    const response = await axiosInstance.get(
      COMPANY_API + `?${param?.payload || ""}`
    );
    return response;
  } catch (error) {
    return await error;
  }
};

export const getCompanyAPI = async (params) => {
  try {
    return await axiosInstance.get(COMPANY_API, { params });
  } catch (error) {
    return await error;
  }
};

export const getCompanyById = async (_id) => {
  try {
    const response = await axiosInstance.get(COMPANY_API + `/${_id}`);
    return response;
  } catch (error) {
    return await error;
  }
};

export const addCompanyAPI = async (body) => {
  try {
    const response = await axiosInstance.post(COMPANY_API, body);
    return response;
  } catch (error) {
    return await error;
  }
};

export const updateCompanyAPI = async (_id, body) => {
  try {
    return await axiosInstance.put(COMPANY_API + `/${_id}`, body);
  } catch (error) {
    return await error;
  }
};

export const deleteCompanyAPI = async (_id) => {
  try {
    return await axiosInstance.delete(COMPANY_API + `/${_id}`);
  } catch (error) {
    return await error;
  }
};

// Do not update this
export const getCompanyForUserAPI = (param) => {
  return axiosInstance.get(COMPANY_API + `${param || ""}`);
};

export const downloadCompanyFile = async (parm) => {
  try {
    const response = await axiosInstance
      .get(`/pub/export${parm}`, {
        method: "GET",
        responseType: "blob", // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.json"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    return response;
  } catch (error) {
    return await error;
  }
};

export const syncCompanyData = async (parm) => {
  try {
    const response = await axiosInstance
      .get(`/pub/local-export${parm}`, {
        method: "GET"
      });
    return response.data;
  } catch (error) {
    return await error;
  }
};
