import React, { useEffect, useState } from "react";
import {
  Col,
  Layout,
  Row,
  Select,
  Typography,
  Input,
  Dropdown,
  Menu,
  message,
  Tooltip,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  getAllCompanies,
  SelectedCompanyId,
  SelectedDataIdByListing,
  SelectedDeviceId,
  SelectedProjectId,
  toggleCollapsedSideNav,
} from "../../appRedux/actions";
import UserInfo from "../../components/UserInfo";
import Auxiliary from "util/Auxiliary";
import AsyncDropDown from "../../components/AsyncDropdown";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../appRedux/API/axios";
import { SelectedLocationId } from "../../appRedux/actions/Location";
import { useHistory } from "react-router-dom";
import { assetTypes } from "../../util/common";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { globalSearch, DownloadFile } from "../../appRedux/API/dashboard";

import PDF from "../../components/PDF";

const extraAssetTypes = { ...assetTypes, bms: "BMS" };
const { Header } = Layout;
const hideCompany = [
  "edit-project",
  "add-project",
  "company-listing",
  "edit-company",
  "add-device-type",
  "device-type-details",
  "edit-device-type",
  "device-type-listing",
  "add-company",
  "user-profile",
  "active-devices",
];

const Topbar = () => {
  // const targetRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { Search } = Input;
  const { Option } = Select;
  const { navStyle } = useSelector(({ settings }) => settings);
  const dataSelect = useSelector((data) => data);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const [companies, setCompanies] = useState([]);
  const [companyValue, setCompanyValue] = useState({
    name: localStorage.getItem("selectedCompanyName"),
    _id: localStorage.getItem("selectedCompanyId"),
  });
  const companiesData = useSelector((state) => state.companies.companies);
  const [deviceValue, setDeviceValue] = useState({});
  const [projectValue, setProjectValue] = useState({});
  const [locationValue, setLocationValue] = useState({});
  const [assetValue, setAssetValue] = useState("mainSignal");
  const [stationAssets, setstationAssets] = useState([]);
  const [devices, setDevices] = useState();
  const [result, setResult] = useState();

  const [isFirstLoad, setisFirstLoad] = useState({
    projectKey: 0,
    locationKey: 0,
    deviceKey: 0,
  });

  const fetchDeviceById = async () => {
    setisFirstLoad({
      ...isFirstLoad,
      projectKey: `P${isFirstLoad?.projectKey + 1}`,
      deviceKey: `D${isFirstLoad?.deviceKey + 1}`,
      locationKey: `L${isFirstLoad?.locationKey + 1}`,
    });
    setCompanyValue({
      name:
        localStorage.getItem("selectedCompanyName") ||
        dataSelect?.companies?.selectedCompanyName,
      _id:
        localStorage.getItem("selectedCompanyId") ||
        dataSelect?.companies?.selectedCompanyId,
    });
    setProjectValue({
      name:
        dataSelect?.projects?.selectProjectName ||
        localStorage.getItem("selectedProjectName"),
      _id:
        dataSelect?.projects?.selectedProjectId ||
        localStorage.getItem("selectedProjectId"),
    });

    setLocationValue({
      locationBox:
        dataSelect?.location?.selectLocationName ||
        localStorage.getItem("selectedLocationName"),
      _id:
        dataSelect?.location?.selectedLocationId ||
        localStorage.getItem("selectedLocationId"),
    });

    setDeviceValue({
      deviceName:
        dataSelect?.device?.selectedDeviceName ||
        localStorage.getItem("selectedDeviceName"),
      _id:
        dataSelect?.device?.selectedDeviceId ||
        localStorage.getItem("selectedDeviceId"),
    });
  };

  const companyChangeHandler = async (company) => {
    setisFirstLoad({
      ...isFirstLoad,
      projectKey: `P${isFirstLoad?.projectKey + 1}`,
      deviceKey: `D${isFirstLoad?.deviceKey + 1}`,
      locationKey: `L${isFirstLoad?.locationKey + 1}`,
    });
    setCompanyValue(company);

    dispatch(SelectedCompanyId(company?._id || "", company?.name || ""));
    localStorage.setItem("selectedCompanyId", company?._id || "");
    localStorage.setItem("selectedCompanyName", company?.name || "");

    dispatch(
      SelectedProjectId(
        company?.projects?._id || "",
        company?.projects?.name || ""
      )
    );
    localStorage.setItem("selectedProjectId", company?.projects?._id || "");
    localStorage.setItem("selectedProjectName", company?.projects?.name || "");

    dispatch(
      SelectedLocationId(
        company?.locationBox?._id || "",
        company?.locationBox?.locationBox || ""
      )
    );
    localStorage.setItem("selectedLocationId", company?.locationBox?._id || "");
    localStorage.setItem(
      "selectedLocationName",
      company?.locationBox?.locationBox || ""
    );

    dispatch(
      SelectedDeviceId(
        company?.devices?._id || "",
        company?.devices?.deviceName || ""
      )
    );
    localStorage.setItem("selectedDeviceId", company?.devices?._id || "");
    localStorage.setItem(
      "selectedDeviceName",
      company?.devices?.deviceName || ""
    );
    let dataObj = {
      projectId: "",
      projectName: "",
      locationName: "",
      locationId: "",
      deviceName: "",
      deviceId: "",
    };
    dispatch(SelectedDataIdByListing(dataObj));
  };

  const deviceChangeHandler = (device) => {
    setDeviceValue(device);
    dispatch(SelectedDeviceId(device._id, device?.deviceName));
    localStorage.setItem("selectedDeviceId", device._id);
    localStorage.setItem("selectedDeviceName", device.deviceName);
    // localStorage?.setItem("selectedLocationId", device?.locationId);
    localStorage?.setItem("locationSelected", device?.locationId);
    localStorage.setItem("selectedLocationName", device.locationBox);
  };

  const projectChangeHandler = (project, type, option) => {
    if (type === "assets") {
      history.push(
        location.pathname.split("/").length === 2
          ? "assets/" + option.value
          : option.value
      );
    } else if (type === "location") {
      setLocationValue(project);
      dispatch(SelectedLocationId(project._id, project?.locationBox));
      localStorage.setItem("selectedLocationId", project._id);
      localStorage.setItem("selectedLocationName", project.locationBox);

      if (project?._id !== "all") {
        dispatch(
          SelectedDeviceId(
            project?.devices?._id || "",
            project?.devices?.deviceId
              ? `${project?.devices?.deviceName}-(${project?.devices?.deviceId})`
              : project?.devices?.deviceName
          )
        );
        localStorage.setItem("selectedDeviceId", project?.devices?._id || "");
        localStorage.setItem(
          "selectedDeviceName",
          project?.devices?.deviceId
            ? `${project?.devices?.deviceName}-(${project?.devices?.deviceId})`
            : project?.devices?.deviceName
        );
      }

      // const arrClear = ["selectedDeviceId", "selectedDeviceName"];
      // arrClear.forEach((item) => {
      //   localStorage.setItem(item, "");
      // });
      // setDeviceValue({
      //   deviceName: "",
      //   _id: "",
      // });
      setisFirstLoad({
        ...isFirstLoad,
        deviceKey: `D${isFirstLoad?.deviceKey + 1}`,
      });
    } else {
      setProjectValue(project);
      dispatch(SelectedProjectId(project._id, project?.name));
      dispatch(SelectedLocationId("", ""));
      localStorage.setItem("selectedProjectId", project._id);
      localStorage.setItem("selectedProjectName", project.name);
      if (location?.pathname?.split("/")[1] === "assets") {
        dispatch(SelectedLocationId("", ""));
        localStorage.setItem("selectedLocationId", "");
        localStorage.setItem("selectedLocationName", "");
        dispatch(SelectedDeviceId("", ""));
        localStorage.setItem("selectedDeviceId", "");
        localStorage.setItem("selectedDeviceName", "");
        setstationAssets(
          Object.keys(project?.assets || {}).filter(
            (key) => project?.assets?.[key]
          )
        );
      } else {
        dispatch(
          SelectedLocationId(
            project?.locationBox?._id || "",
            project?.locationBox?.locationBox || ""
          )
        );
        localStorage.setItem(
          "selectedLocationId",
          project?.locationBox?._id || ""
        );
        localStorage.setItem(
          "selectedLocationName",
          project?.locationBox?.locationBox || ""
        );

        dispatch(
          SelectedDeviceId(
            project?.devices?._id || "",
            project?.devices?.deviceName || ""
          )
        );
        localStorage.setItem("selectedDeviceId", project?.devices?._id || "");
        localStorage.setItem(
          "selectedDeviceName",
          project?.devices?.deviceName || ""
        );
      }
      setisFirstLoad({
        ...isFirstLoad,
        deviceKey: `D${isFirstLoad?.deviceKey + 1}`,
        locationKey: `L${isFirstLoad?.locationKey + 1}`,
      });
      if (location?.pathname?.split("/")[1] === "dashboard")
        history.push("/stations/stations-details/" + project._id);
    }
  };

  const deviceLoadOptions = async (searchQuery, loadedOptions, { page }) => {
    if (searchQuery && searchQuery.length < 1)
      return {
        options: [],
        hasMore: [],
        additional: {
          page: 1,
        },
      };
    if (locationValue?._id) {
      let parm = locationValue?._id
        ? `&locationId=${locationValue?._id}&companyId=${companyValue?._id}&stationId=${projectValue?._id}`
        : "";
      const response = await axiosInstance.get(
        location?.pathname?.split("/")[1] === "assets"
          ? `api/location/device?&type=listing&page=${page}&limit=10&deviceName=${searchQuery}${parm}&assetType=${
              extraAssetTypes[location.pathname.split("/")[2]] || "Main Signal"
            }`
          : `api/location/device?&type=listing&page=${page}&limit=10&deviceName=${searchQuery}${parm}`
      );
      if (response.data.status === 200) {
        return {
          options: response.data.data.devices,
          hasMore: loadedOptions.length !== response.data.data.totalRecords,
          additional: {
            page: searchQuery ? 2 : page + 1,
          },
        };
      } else if (response?.status === 204) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 1,
          },
        };
      }
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  };

  const locationLoadOptions = async (searchQuery, loadedOptions, { page }) => {
    if (searchQuery && searchQuery.length < 1)
      return {
        options: [],
        hasMore: [],
        additional: {
          page: 1,
        },
      };
    if (projectValue?._id && projectValue?._id !== "all") {
      const response = await axiosInstance.get(
        location?.pathname?.split("/")[1] === "assets"
          ? `api/location?&type=listing&locationBox=${searchQuery}&page=${page}&limit=10&projectId=${
              projectValue?._id
            }&assetType=${
              extraAssetTypes[location.pathname.split("/")[2]] || "Main Signal"
            }`
          : `api/location?&type=listing&locationBox=${searchQuery}&page=${page}&limit=10&projectId=${projectValue?._id}`
      );
      if (response.data.status === 200) {
        return {
          // options: response.data.data.locationBox,
          options:
            page == 1
              ? [
                  { _id: "all", locationBox: "All" },
                  ...response.data.data.locationBox,
                ]
              : response.data.data.locationBox,
          hasMore: loadedOptions.length !== response.data.data.totalRecords,
          additional: {
            page: searchQuery ? 2 : page + 1,
          },
        };
      } else if (response?.status === 204) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 1,
          },
        };
      }
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  };

  const projectLoadOptions = async (searchQuery, loadedOptions, { page }) => {
    if (searchQuery && searchQuery.length < 1)
      return {
        options: [],
        hasMore: [],
        additional: {
          page: 1,
        },
      };
    if (companyValue?._id) {
      const response = await axiosInstance.get(
        `api/project?&type=listing&name=${searchQuery}&page=${page}&limit=10&companyId=${companyValue?._id}`
      );
      if (response.data.status === 200) {
        return {
          options:
            page == 1 && location?.pathname?.split("/")[1] === "assets"
              ? [{ _id: "all", name: "All" }, ...response.data.data.projects]
              : response.data.data.projects,
          hasMore: loadedOptions.length !== response.data.data.totalRecords,
          additional: {
            page: searchQuery ? 2 : page + 1,
          },
        };
      } else if (response?.status === 204) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 1,
          },
        };
      }
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  };

  const companyLoadOptions = async (searchQuery, loadedOptions, { page }) => {
    if (searchQuery && searchQuery.length < 1)
      return {
        options: [],
        hasMore: [],
        additional: {
          page: 1,
        },
      };

    let searchString = `&page=${page}&limit=10`;
    if (searchQuery) {
      searchString = `${searchString}&name=${searchQuery}`;
    }
    const response = await axiosInstance.get(
      `api/company?&type=listing${searchString}`
    );
    if (response.data.status === 200) {
      return {
        options: response.data.data.companies,
        hasMore: loadedOptions.length !== response.data.data.totalRecords,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } else if (response?.status === 204) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  };

  useEffect(() => {
    if (location.pathname.split("/")[1] === "assets") history.push("/assets");
  }, [projectValue?._id]);

  useEffect(() => {
    if (!devices) {
      fetchDeviceById();
    }
    // eslint-disable-next-line
  }, [dataSelect, devices]);

  useEffect(() => {
    if (!companies.length) {
      dispatch(getAllCompanies());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (companiesData.status === 200) {
      setCompanies(companiesData?.data?.company);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname.split("/")[1] === "assets")
      // setAssetValue({
      //   _id: location.pathname.split("/")[2] || "mainSignal",
      //   name:
      //     (location.pathname.split("/")[2] === "mainSignal"
      //       ? "Signal"
      //       : extraAssetTypes[location.pathname.split("/")[2]]) || "Signal",
      // });
      setAssetValue(location.pathname.split("/")[2]);
  }, [location.pathname]);

  const handleSelect = (e) => {
    setDevices(e);
  };

  const handleGlobalSearch = async (searchTerm) => {
    // if (searchTerm?.length > 2) {
    let payload = {
      search: searchTerm,
      // limit: 10,
      // page: 1,
    };
    setTimeout(async () => {
      const result = await globalSearch(payload);
      if (result?.data?.status === 200) {
        const data = result.data.data.data.map((item) => ({
          ...item,
          label: `${item.deviceName} (${item.location?.name}, ${item.station?.name})`,
          value: item._id,
        }));
        setResult(data);
      } else {
        setResult([]);
      }
    }, 2000);

    // }
  };

  const handleOnSelect = (value, option) => {
    setCompanyValue({
      _id: option?.station?.company?._id,
      name: option?.station?.company?.name,
    }); // for select company

    setLocationValue({
      locationBox: option?.location?.name,
      _id: option?.location?._id,
    }); // for location
    setProjectValue({
      _id: option?.station?._id,
      name: option?.station?.name,
    }); //for station done
    setDeviceValue({
      deviceName: option?.label,
      _id: value,
    }); // for device select
    localStorage.setItem("selectedCompanyId", option?.station?.company?._id);
    localStorage.setItem("selectedDeviceId", value);
    localStorage.setItem("selectedDeviceName", option?.label);
    history.push(`/location/location-details/${option?.location?._id}`);
  };

  const [reportData, setReportData] = useState({});

  const downloadFile = async (type) => {
    if (companyValue?._id) {
      const payload = {
        type: type,
        companyId: companyValue?._id,
        // stationId: projectValue?._id || "",
      };
      const response = await DownloadFile(payload);
      if (response?.data?.status === 200) {
        if (type === "pdf") {
          setReportData(response?.data?.data);
        }
        type !== "pdf" && message.info(response.data?.message);
      } else message.error(response?.data?.message);
    } else message.error("Please select company to download the report");
  };

  return (
    <Header className="gx-d-flex top">
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={"/assets/images/w-logo.png"} />
      </Link>

      {/* <SearchBox
        styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
        placeholder="Search in app..."
        onChange={updateSearchChatUser}
        value={searchText}
      /> */}
      {/* <Select
        size={"middle"}
        defaultValue={companyOptions[0]}
        onChange={handleChange}
        placeholder="Select Company"
        style={{ width: 200 }}
        options={companyOptions}
      /> */}
      <div className="w-full ">
        <Row className="w-full gx-d-flex gx-justify-content-end gx-align-items-center">
          {/* {location?.pathname?.split("/")[1] !== "assets" && (
              <Col span={4} className="grid ">
                <Typography.Text className="typography gx-fs-sm">
                  Search Devices
                </Typography.Text>
                <Select
                  className=" gx-my-1 w-full"
                  showSearch
                  suffixIcon={<SearchOutlined />}
                  value={devices}
                  onChange={handleSelect}
                  placeholder={"Search Device"}
                  filterOption={false}
                  options={result}
                  onSelect={handleOnSelect}
                  onSearch={handleGlobalSearch}
                />
              </Col>
            )} */}

          {/* <Col span={4} className="grid"> */}
          <PDF companyReportData={reportData} />
          {/* </Col> */}
          {hideCompany.includes(location.pathname.split("/")[2]) ||
          ["card", "card-type", "sub-card-type"].includes(
            location.pathname.split("/")[1]
          ) ? (
            ""
          ) : (
            <>
              <Col span={4} className="grid ">
                <Typography.Text className="typography gx-fs-sm">
                  Select Company
                </Typography.Text>
                <AsyncDropDown
                  value={companyValue}
                  onChange={(company) => companyChangeHandler(company)}
                  className="gx-my-1"
                  name="companyId"
                  placeholder="Company"
                  labelName="name"
                  loadOptions={companyLoadOptions}
                />
              </Col>
              {/* <Col span={4} className="grid gx-gap-3">
                <Typography.Text className="typography gx-fs-sm">
                  Select Stations
                </Typography.Text>
                <AsyncDropDown
                  value={projectValue}
                  onChange={(project) => projectChangeHandler(project)}
                  name="projectId"
                  placeholder="Project"
                  className="gx-my-1"
                  labelName="name"
                  loadOptions={projectLoadOptions}
                  renderValue={true}
                  key={isFirstLoad?.projectKey}
                />
              </Col>
              <Col span={4} className="grid gx-gap-3">
                <Typography.Text className="typography gx-fs-sm">
                  Select Location
                </Typography.Text>
                <AsyncDropDown
                  value={locationValue}
                  onChange={(location) =>
                    projectChangeHandler(location, "location")
                  }
                  name="locationBox"
                  placeholder="locationBox"
                  labelName="locationBox"
                  loadOptions={locationLoadOptions}
                  className="gx-my-1"
                  renderValue={true}
                  key={isFirstLoad?.locationKey}
                />
              </Col> */}
            </>
          )}

          {(location.pathname.split("/")[2] === "location-details" ||
            location.pathname.split("/")[2] === "maintenance-listing" ||
            location.pathname.split("/")[2] === "stations-details" ||
            location?.pathname?.split("/")[1] === "dashboard" ||
            location?.pathname?.split("/")[1] === "assets" ||
            location?.pathname?.split("/")[1] === "configure-card" ||
            location.pathname.split("/")[2] === "add-maintenance") && (
            <>
              <Col span={4} className="grid gx-gap-3">
                <Typography.Text className="typography gx-fs-sm">
                  Select Stations
                </Typography.Text>
                <AsyncDropDown
                  value={
                    location?.pathname?.split("/")[1] === "dashboard"
                      ? { name: "All", _id: "all" }
                      : projectValue
                  }
                  onChange={(project) => projectChangeHandler(project)}
                  name="projectId"
                  placeholder="Project"
                  className="gx-my-1"
                  labelName="name"
                  loadOptions={projectLoadOptions}
                  renderValue={true}
                  key={isFirstLoad?.projectKey}
                />
              </Col>
              {location?.pathname?.split("/")[1] === "assets" && (
                <Col span={4} className="grid gx-gap-3">
                  <Typography.Text className="typography gx-fs-sm">
                    Asset Type
                  </Typography.Text>
                  {/* <AsyncDropDown
                    onChange={(project) =>
                      projectChangeHandler(project, "assets")
                    }
                    name="asset"
                    labelName="name"
                    loadOptions={() => ({
                      options: Object.keys(extraAssetTypes)
                        .filter(
                          (key) =>
                            ![
                              "shuntSignal",
                              "cOnSignal",
                              "routeSignal",
                            ].includes(key) && stationAssets?.includes(key)
                        )
                        .map((key) => ({
                          name:
                            key === "mainSignal"
                              ? "Signal"
                              : extraAssetTypes[key],
                          // _id: extraAssetTypes[key]?.toLowerCase().replace(/ /g, "-"),
                          _id: key,
                        })),
                    })}
                    className="gx-my-1"
                    renderValue={true}
                    key="asset"
                    value={assetValue}
                  /> */}
                  <Select
                    className="gx-w-100 gx-my-1"
                    onChange={(project, option) =>
                      projectChangeHandler(project, "assets", option)
                    }
                    name="asset"
                    options={Object.keys(extraAssetTypes)
                      .filter(
                        (key) =>
                          !["shuntSignal", "cOnSignal", "routeSignal"].includes(
                            key
                          ) &&
                          (projectValue?._id === "all" ||
                            stationAssets?.includes(key))
                      )
                      .map((key) => ({
                        label:
                          key === "mainSignal"
                            ? "Signal"
                            : extraAssetTypes[key],
                        // _id: extraAssetTypes[key]?.toLowerCase().replace(/ /g, "-"),
                        value: key,
                      }))}
                    key="asset"
                    value={assetValue}
                  />
                </Col>
              )}
              {((location.pathname.split("/")[2] !== "add-maintenance" &&
                location?.pathname?.split("/")[1] !== "dashboard") ||
                location?.pathname?.split("/")[1] === "assets") &&
                location.pathname.split("/")[2] !== "stations-details" && (
                  <Col span={4} className="grid gx-gap-3">
                    <Typography.Text className="typography gx-fs-sm">
                      Select Location
                    </Typography.Text>
                    <AsyncDropDown
                      // value={locationValue}
                      value={locationValue}
                      onChange={(location) =>
                        projectChangeHandler(location, "location")
                      }
                      isDisabled={
                        location?.pathname?.split("/")[1] === "assets" &&
                        !assetValue
                      }
                      name="locationBox"
                      placeholder="locationBox"
                      labelName="locationBox"
                      loadOptions={locationLoadOptions}
                      className="gx-my-1"
                      renderValue={true}
                      key={isFirstLoad?.locationKey}
                    />
                  </Col>
                )}
              {(location.pathname.split("/")[2] === "location-details" ||
                // location.pathname.split("/")[2] === "stations-details" ||
                location?.pathname?.split("/")[1] === "assets") && (
                <Col span={4} className="grid gx-gap-3">
                  <Typography.Text className="typography gx-fs-sm">
                    Select Device
                  </Typography.Text>
                  <AsyncDropDown
                    value={deviceValue}
                    isDisabled={
                      location?.pathname?.split("/")[1] === "assets" &&
                      !assetValue
                    }
                    onChange={(device) => deviceChangeHandler(device)}
                    name="device"
                    placeholder="Device"
                    labelName="deviceName"
                    loadOptions={deviceLoadOptions}
                    className="gx-my-1"
                    renderValue={true}
                    key={isFirstLoad?.deviceKey}
                  />
                </Col>
              )}
            </>
          )}
          {(location?.pathname?.split("/")[2] === "project-details" ||
            location?.pathname?.split("/")[2] === "location-listing") && (
            <Col span={4} className="grid gx-gap-3">
              <Typography.Text className="typography gx-fs-sm">
                Select Stations
              </Typography.Text>
              <AsyncDropDown
                value={projectValue}
                onChange={(project) => projectChangeHandler(project)}
                name="projectId"
                placeholder="Project"
                labelName="name"
                loadOptions={projectLoadOptions}
                className="gx-my-1"
                renderValue={true}
                key={isFirstLoad?.projectKey}
              />
            </Col>
          )}
          {location.pathname.split("/")[1] === "dashboard" && (
            <Col span={1} className="grid">
              <Tooltip placement="right" title={"Download Report"}>
                <Dropdown
                  className="gx-mt-3 gx-my-0 gx-p-2 "
                  overlay={
                    <Menu>
                      <Menu.Item key="0" onClick={() => downloadFile("xlsx")}>
                        Download xlsx
                      </Menu.Item>
                      <Menu.Item key="1" onClick={() => downloadFile("pdf")}>
                        Download pdf
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottom"
                  arrow={{
                    pointAtCenter: true,
                  }}
                  trigger={"click"}
                >
                  <div
                    style={{
                      backgroundColor: "#E9EDF8",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <DownloadOutlined />
                  </div>
                </Dropdown>
              </Tooltip>
            </Col>
          )}
          <Col>
            <ul className="gx-header-notifications gx-ml-auto">
              {/* <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={
              <SearchBox
                styleName="gx-popover-search-bar"
                placeholder="Search in app..."
                onChange={updateSearchChatUser}
                value={searchText}
              />
            }
            trigger="click"
          >
            <span className="gx-pointer gx-d-block">
              <i className="icon icon-search-new" />
            </span>
          </Popover>
        </li> */}
              {/* {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-notify">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                       trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                       content={<MailNotification/>} trigger="click">
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new"/>
                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                  </span>
              </Popover>
            </li>
          </Auxiliary>
        } */}
              {/* <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                   trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
          </Popover>
        </li> */}
              {/* {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo/></li>
          </Auxiliary>
        } */}
              <Auxiliary>
                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              </Auxiliary>
            </ul>
          </Col>
        </Row>
      </div>
    </Header>
  );
};

export default Topbar;
