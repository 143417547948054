import axiosInstance from "./axios";

const GLOBAL_SEARCH = "/api/dashboard/search";
const DOWNLOAD_REPORT = "/api/dashboard/company-report";

export const globalSearch = async (param) => {
  try {
    return await axiosInstance.get(GLOBAL_SEARCH, {
      params: param,
    });
  } catch (error) {
    return error;
  }
};

export const DownloadFile = async (payload) => {
  try {
    return await axiosInstance.get(DOWNLOAD_REPORT, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};
