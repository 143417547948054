import React from "react";
import "./style.css";
import { Typography } from "antd";

export default function OtpInput({
  setOtpValues,
  otpValues,
  error = "",
  erorMsg,
  className,
}) {
  const handleChange = (e) => {
    Object.keys(otpValues).includes(e.target.name) && delete erorMsg["otp"];
    if (
      e.target.value !== "" &&
      Object.keys(otpValues).includes(e.target.name) &&
      !/^\d+$/.test(e.target.value)
    )
      return;
    setOtpValues({ ...otpValues, [e.target.name]: e.target.value });
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6 && elmnt.target.value !== "") {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  return (
    <div className={className}>
      <div className="gx-mb-2 gx-text-center ">
        <div
          id="otp"
          className="gx-d-flex  justify-center"
          style={{ gap: "10px" }}
        >
          <input
            className=" form-control form-control-solid"
            style={{ border: error ? "1px solid red" : "" }}
            type="text"
            id="first"
            maxlength="1"
            name="otp1"
            onChange={handleChange}
            onKeyUp={(e) => inputfocus(e)}
            tabIndex="1"
            value={otpValues.otp1}
          />
          <input
            className=" form-control form-control-solid"
            style={{ border: error ? "1px solid red" : "" }}
            type="text"
            id="second"
            name="otp2"
            maxlength="1"
            onChange={handleChange}
            onKeyUp={(e) => inputfocus(e)}
            tabIndex="2"
            value={otpValues.otp2}
          />
          <input
            className=" form-control form-control-solid"
            style={{ border: error ? "1px solid red" : "" }}
            type="text"
            id="third"
            maxlength="1"
            name="otp3"
            value={otpValues.otp3}
            onChange={handleChange}
            tabIndex="3"
            onKeyUp={(e) => inputfocus(e)}
          />
          <input
            className=" form-control form-control-solid"
            style={{ border: error ? "1px solid red" : "" }}
            type="text"
            id="fourth"
            maxlength="1"
            name="otp4"
            value={otpValues.otp4}
            onChange={handleChange}
            onKeyUp={(e) => inputfocus(e)}
            tabIndex="4"
          />
          <input
            className=" form-control form-control-solid"
            style={{ border: error ? "1px solid red" : "" }}
            type="text"
            id="fifth"
            maxlength="1"
            name="otp5"
            value={otpValues.otp5}
            onChange={handleChange}
            onKeyUp={(e) => inputfocus(e)}
            tabIndex="5"
          />
          <input
            className=" form-control form-control-solid"
            style={{ border: error ? "1px solid red" : "" }}
            type="text"
            id="sixth"
            maxlength="1"
            name="otp6"
            value={otpValues.otp6}
            onChange={handleChange}
            onKeyUp={(e) => inputfocus(e)}
            tabIndex="6"
          />
        </div>
      </div>
      {erorMsg?.otp && (
        <div className="gx-mb-3 ">
          <Typography.Text type="danger" level={5}>
            {erorMsg.otp}
          </Typography.Text>
        </div>
      )}
    </div>
  );
}
