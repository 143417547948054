import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_USER_FAILED,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_USER_FAILED,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../../constants/ActionTypes";
import {
  addUserAPI,
  getUserAPI,
  getAllUsersAPI,
  updateUserAPI,
  deleteUserAPI,
} from "../API/Users";

function* getAllUsers(action) {
  try {
    const responseCheck = yield call(getAllUsersAPI, action);
    // const response = getAllUSersResponse;
    if (responseCheck?.data?.status === 200) {
      yield put({
        type: GET_ALL_USERS_SUCCESS,
        users: responseCheck?.data,
        loading: false,
      });
    } else if (responseCheck?.status === 204) {
      yield put({
        type: GET_ALL_USERS_SUCCESS,
        users: {
          status: 204,
        },
        loading: false,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ALL_USERS_FAILED,
      message: error.message,
      loading: false,
    });
  }
}

function* getUser(action) {
  try {
    const response = yield call(getUserAPI);
    yield put({ type: GET_USER_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: GET_USER_FAILED, message: error.message });
  }
}

function* addUser(action) {
  try {
    const response = yield call(addUserAPI);
    yield put({ type: ADD_USER_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: ADD_USER_FAILED, message: error.message });
  }
}

function* updateUser(action) {
  try {
    const response = yield call(updateUserAPI);
    yield put({ type: UPDATE_USER_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: UPDATE_USER_FAILED, message: error.message });
  }
}

function* deleteUser(action) {
  try {
    const response = yield call(deleteUserAPI);
    yield put({ type: DELETE_USER_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: DELETE_USER_FAILED, message: error.message });
  }
}

function* usersSaga() {
  yield takeLatest(GET_ALL_USERS_REQUEST, getAllUsers);
  yield takeLatest(GET_USER_REQUEST, getUser);
  yield takeLatest(ADD_USER_REQUEST, addUser);
  yield takeLatest(UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(DELETE_USER_REQUEST, deleteUser);
}

export default usersSaga;
