import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import usersSagas from "./Users";
import projectsSagas from "./Projects";
import companiesSagas from "./Companies";
import eventsSagas from "./Events";
import alertsSagas from "./Alerts";
import analyticsSagas from "./Analytics";
import entitiesSagas from "./Entities";
import deviceTypeSaga from "./deviceType";
import locationSaga from "./Location";
import deviceSaga from "./Device";

export default function* rootSaga() {
  yield all([
    authSagas(),
    notesSagas(),
    usersSagas(),
    deviceTypeSaga(),
    companiesSagas(),
    alertsSagas(),
    analyticsSagas(),
    eventsSagas(),
    projectsSagas(),
    locationSaga(),
    entitiesSagas(),
    deviceSaga()
  ]);
}
