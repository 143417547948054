import axios from "axios";

export const BaseUrl = {
  LOCAL: process.env.REACT_APP_LOCAL_URL,
  DEV: process.env.REACT_APP_DEV_URL,
  QA: process.env.REACT_APP_QA_URL,
  PROD: process.env.REACT_APP_PROD_URL,
}[process.env.REACT_APP_PORT];
// }[process.env.REACT_APP_PORT];

const axiosInstance = axios.create({
  baseURL: BaseUrl,
});

let check;
let refreshObj = {};

export const checkUnauthorize = async () => {
  try {
    return await axiosInstance.post("/pub/token", {
      email: JSON.parse(localStorage.user)?.email,
      refreshToken: localStorage.getItem("refreshToken"),
    });
  } catch (error) {
    return await error;
  }
};

async function checkData() {
  let response = await checkUnauthorize();
  refreshObj = response?.data;
}

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.data?.accessToken) {
      axiosInstance.defaults.headers["token"] =
        response?.data?.data?.accessToken;
      localStorage.setItem("token", response?.data?.data?.accessToken);
      localStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 && !check && !refreshObj?.status) {
      check = true;
      checkData();
    }
    if (
      error?.response?.data?.key === "logout" &&
      error?.response?.data?.status === 401
    ) {
      localStorage.clear();
      window.location.href = "/signin";
    }
    if (refreshObj?.status && refreshObj?.status !== 200) {
      localStorage.clear();
      window.location.href = "/signin";
    }
    return Promise.reject(
      (error.response && error.response) || "Something went wrong!"
    );
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.headers["token"] = localStorage.token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
