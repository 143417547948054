import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
} from "../../constants/ActionTypes";
import { getAnalyticsAPI } from "../API/Analytics";

function* getAnalytics(action) {
  try {
    const companyId = action.payload;
    const response = yield call(getAnalyticsAPI, companyId);
    if (response?.data?.status === 200) {
      yield put({
        type: GET_ANALYTICS_SUCCESS,
        analytics: response?.data?.data,
      });
    } else {
      yield put({
        type: GET_ANALYTICS_SUCCESS,
        analytics: {},
        loading: false,
      });
    }
  } catch (error) {
    yield put({ type: GET_ANALYTICS_FAILED, message: error.message });
  }
}

function* analyticsSaga() {
  yield takeLatest(GET_ANALYTICS_REQUEST, getAnalytics);
}

export default analyticsSaga;
