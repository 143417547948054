import {
  ADD_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST,
  GET_ALL_PROJECTS_REQUEST,
  GET_PROJECT_REQUEST,
  SET_SELECTED_PROJECT,
  SET_SELECTED_PROJECT_ID_BY_LISTING,
  UPDATE_PROJECT_REQUEST,
} from "../../constants/ActionTypes";

// export const onGetSticky = (notesList) => {
//   return {
//     type: GET_STICKY,
//     notesList,
//   };
// };

// export const onUpdateSticky = (notesList) => {
//   return {
//     type: NOTES_UPDATE,
//     notesList,
//   };
// };

// export const updateStickySuccess = (notesList) => {
//   return {
//     type: UPDATE_ALL_NOTES_SUCCESS,
//     payload: notesList,
//   };
// };

export const getAllProjects = (project) => {
  return { type: GET_ALL_PROJECTS_REQUEST, payload: project || "" };
};

export const getProject = () => {
  return { type: GET_PROJECT_REQUEST };
};

export const addProject = () => {
  return { type: ADD_PROJECT_REQUEST };
};

export const updateProject = () => {
  return { type: UPDATE_PROJECT_REQUEST };
};

export const deleteProject = () => {
  return { type: DELETE_PROJECT_REQUEST };
};

export const SelectedProjectId = (payload, name) => {
  return { type: SET_SELECTED_PROJECT, projectId: payload, projectName: name };
};

export const SelectedDataIdByListing = (payload, name) => {
  const {
    deviceId,
    deviceName,
    locationId,
    locationName,
    projectId,
    projectName,
  } = payload;
  return {
    type: SET_SELECTED_PROJECT_ID_BY_LISTING,
    deviceId: deviceId || "",
    deviceName: deviceName || "",
    locationId: locationId || "",
    locationName: locationName || "",
    projectId: projectId || "",
    projectName: projectName || "",
  };
};
