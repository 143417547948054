import {
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  error: false,
  analytics: [],
};

const AnalyticsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANALYTICS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_ANALYTICS_SUCCESS: {
      return { ...state, analytics: action.analytics, loading: false };
    }
    case GET_ANALYTICS_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default AnalyticsReducer;
