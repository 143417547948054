import axiosInstance from "./axios";

export const getAlertsByTimeAPI = () => {
  return axiosInstance.get("https://gorest.co.in/public/v2/users");
};

export const getAllAlertsAPI = () => {
  return axiosInstance.get(`/pub/alert?type=listing`);
};

export const createAlertAPI = () => {
  return axiosInstance.get("https://gorest.co.in/public/v2/users");
};

export const updateAlertAPI = () => {
  return axiosInstance.get("https://gorest.co.in/public/v2/users");
};

export const deleteAlertAPI = () => {
  return axiosInstance.get("https://gorest.co.in/public/v2/users");
};
