import axiosInstance from "./axios";

const DEVICE_TYPE_API = "/api/device-type";
const DEVICE_TYPE_LIST = "/api/device-type/list";
const DELETE_MODE = "/api/device-type/mode";

export const getAllDeviceTypeAPI = async (param) => {
  try {
    return await axiosInstance.get(DEVICE_TYPE_API + `${param || ""}`);
  } catch (error) {
    return error;
  }
};

export const getDeviceTypeAPI = async (_id) => {
  try {
    return await axiosInstance.get(DEVICE_TYPE_API + `/${_id}`);
  } catch (error) {
    return error;
  }
};

export const addDeviceTypeAPI = async (body) => {
  try {
    return await axiosInstance.post(DEVICE_TYPE_API, body);
  } catch (error) {
    return error;
  }
};

export const updateDeviceTypeAPI = async (_id, body) => {
  try {
    return await axiosInstance.put(DEVICE_TYPE_API + `/${_id}`, body);
  } catch (error) {
    return error;
  }
};

export const deleteDeviceTypeAPI = async (_id) => {
  try {
    return await axiosInstance.delete(DEVICE_TYPE_API + `/${_id}`);
  } catch (error) {
    return error;
  }
};

export const deleteModeDeviceTypeAPI = async (params) => {
  try {
    return await axiosInstance.delete(DELETE_MODE + `${params}`);
  } catch (error) {
    return error;
  }
};

export const deviceTypeListAPI = (params) =>
  axiosInstance.get(DEVICE_TYPE_LIST, { params });
