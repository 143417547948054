import {
  ADD_USER_FAILED,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_USER_FAILED,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  error: false,
  users: [],
};

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_ALL_USERS_SUCCESS: {
      return { ...state, users: action?.users, loading: false };
    }
    case GET_ALL_USERS_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case GET_USER_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_USER_SUCCESS: {
      return { ...state, loading: true };
    }
    case GET_USER_FAILED: {
      return { ...state, loading: true };
    }

    case ADD_USER_REQUEST: {
      return { ...state, loading: true };
    }
    case ADD_USER_SUCCESS: {
      return { ...state, loading: true };
    }
    case ADD_USER_FAILED: {
      return { ...state, loading: true };
    }

    case UPDATE_USER_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_USER_SUCCESS: {
      return { ...state, loading: true };
    }
    case UPDATE_USER_FAILED: {
      return { ...state, loading: true };
    }

    case DELETE_USER_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_USER_SUCCESS: {
      return { ...state, loading: true };
    }
    case DELETE_USER_FAILED: {
      return { ...state, loading: true };
    }

    default:
      return state;
  }
};

export default UserReducer;
