import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_EVENT_FAILED,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  DELETE_EVENT_FAILED,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  GET_EVENTS_BY_TIME_FAILED,
  GET_EVENTS_BY_TIME_REQUEST,
  GET_EVENTS_BY_TIME_SUCCESS,
  UPDATE_EVENT_REQUEST,
} from "../../constants/ActionTypes";
import {
  createEventAPI,
  getEventsByTimeAPI,
  updateEventAPI,
  deleteEventAPI,
} from "../API/Events";

function* getEventsByTime(action) {
  try {
    const response = yield call(getEventsByTimeAPI);
    yield put({ type: GET_EVENTS_BY_TIME_SUCCESS, events: response.data });
  } catch (error) {
    yield put({ type: GET_EVENTS_BY_TIME_FAILED, message: error.message });
  }
}

function* createEvent(action) {
  try {
    const response = yield call(createEventAPI);
    yield put({ type: CREATE_EVENT_SUCCESS, events: response.data });
  } catch (error) {
    yield put({ type: CREATE_EVENT_FAILED, message: error.message });
  }
}

function* updateEvent(action) {
  try {
    const response = yield call(updateEventAPI);
    yield put({ type: CREATE_EVENT_SUCCESS, events: response.data });
  } catch (error) {
    yield put({ type: CREATE_EVENT_FAILED, message: error.message });
  }
}

function* deleteEvent(action) {
  try {
    const response = yield call(deleteEventAPI);
    yield put({ type: DELETE_EVENT_SUCCESS, events: response.data });
  } catch (error) {
    yield put({ type: DELETE_EVENT_FAILED, message: error.message });
  }
}

function* eventsSaga() {
  yield takeLatest(GET_EVENTS_BY_TIME_REQUEST, getEventsByTime);
  yield takeLatest(CREATE_EVENT_REQUEST, createEvent);
  yield takeLatest(UPDATE_EVENT_REQUEST, updateEvent);
  yield takeLatest(DELETE_EVENT_REQUEST, deleteEvent);
}

export default eventsSaga;
