import {
  ADD_COMPANY_REQUEST,
  DELETE_COMPANY_REQUEST,
  GET_ALL_COMPANIES_REQUEST,
  GET_COMPANY_REQUEST,
  SET_SELECTED_COMPANY,
  UPDATE_COMPANY_REQUEST,
} from "../../constants/ActionTypes";

export const getAllCompanies = (company) => {
  return {
    type: GET_ALL_COMPANIES_REQUEST,
    payload: company || "",
    loading: true,
  };
};

export const getCompany = () => {
  return { type: GET_COMPANY_REQUEST };
};

export const addCompany = (payload) => {
  return { type: ADD_COMPANY_REQUEST, payload };
};

export const updateCompany = (payload) => {
  return { type: UPDATE_COMPANY_REQUEST, payload };
};

export const deleteCompany = (payload) => {
  return { type: DELETE_COMPANY_REQUEST, payload };
};

export const SelectedCompanyId = (payload, name) => {
  return { type: SET_SELECTED_COMPANY, companyId: payload, companyName: name };
};
