import {
  ADD_COMPANY_FAILED,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILED,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  GET_ALL_COMPANIES_FAILED,
  GET_ALL_COMPANIES_REQUEST,
  GET_ALL_COMPANIES_SUCCESS,
  GET_COMPANY_FAILED,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  SET_SELECTED_COMPANY,
  SET_SELECTED_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
} from "../../constants/ActionTypes";
const selectedCompanyId = localStorage.getItem("selectedCompanyId");
const INIT_STATE = {
  loading: false,
  error: false,
  companies: [],
  selectedCompanyId: selectedCompanyId || "",
};

const CompanyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_COMPANIES_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_ALL_COMPANIES_SUCCESS: {
      return { ...state, companies: action?.companies, loading: false };
    }
    case GET_ALL_COMPANIES_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case GET_COMPANY_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_COMPANY_SUCCESS: {
      return { ...state, loading: true };
    }
    case GET_COMPANY_FAILED: {
      return { ...state, loading: true };
    }

    case ADD_COMPANY_REQUEST: {
      return { ...state, loading: true };
    }
    case ADD_COMPANY_SUCCESS: {
      return { ...state, loading: true };
    }
    case ADD_COMPANY_FAILED: {
      return { ...state, loading: true };
    }

    case UPDATE_COMPANY_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_COMPANY_SUCCESS: {
      return { ...state, loading: true };
    }
    case UPDATE_COMPANY_FAILED: {
      return { ...state, loading: true };
    }

    case DELETE_COMPANY_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_COMPANY_SUCCESS: {
      return { ...state, loading: true };
    }
    case DELETE_COMPANY_FAILED: {
      return { ...state, loading: true };
    }
    case SET_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompanyId: action.companyId,
        selectCompanyName: action?.companyName,
      };
    }
    case SET_SELECTED_COMPANY_SUCCESS: {
      return { ...state, loading: true };
    }

    default:
      return state;
  }
};

export default CompanyReducer;
