import axios from "axios";

export const getEventsByTimeAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const createEventAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const updateEventAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const deleteEventAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};
