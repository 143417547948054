import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PROJECT_FAILED,
  ADD_PROJECT_REQUEST,
  ADD_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  GET_ALL_PROJECTS_FAILED,
  GET_ALL_PROJECTS_REQUEST,
  GET_ALL_PROJECTS_SUCCESS,
  GET_PROJECT_FAILED,
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  SET_SELECTED_PROJECT,
  SET_SELECTED_PROJECT_ID_BY_LISTING,
  SET_SELECTED_PROJECT_ID_BY_LISTING_SUCCESS,
  SET_SELECTED_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILED,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
} from "../../constants/ActionTypes";
import {
  addProjectAPI,
  getProjectAPI,
  getAllProjectsAPI,
  updateProjectAPI,
  deleteProjectAPI,
} from "../API/Projects";

function* getAllProjects(action) {
  try {
    const response = yield call(getAllProjectsAPI, action);
    if (response?.data?.status === 200) {
      yield put({
        type: GET_ALL_PROJECTS_SUCCESS,
        projects: response?.data,
        loading: false,
      });
    } else {
      yield put({
        type: GET_ALL_PROJECTS_FAILED,
        projects: [],
        loading: false,
      });
    }
  } catch (error) {
    yield put({ type: GET_ALL_PROJECTS_FAILED, message: error.message });
  }
}

function* getProject(action) {
  try {
    const response = yield call(getProjectAPI);
    yield put({ type: GET_PROJECT_SUCCESS, projects: response.data });
  } catch (error) {
    yield put({ type: GET_PROJECT_FAILED, message: error.message });
  }
}

function* addProject(action) {
  try {
    const response = yield call(addProjectAPI);
    yield put({ type: ADD_PROJECT_SUCCESS, projects: response.data });
  } catch (error) {
    yield put({ type: ADD_PROJECT_FAILED, message: error.message });
  }
}

function* updateProject(action) {
  try {
    const response = yield call(updateProjectAPI);
    yield put({ type: UPDATE_PROJECT_SUCCESS, projects: response.data });
  } catch (error) {
    yield put({ type: UPDATE_PROJECT_FAILED, message: error.message });
  }
}

function* deleteProject(action) {
  try {
    const response = yield call(deleteProjectAPI);
    yield put({ type: DELETE_PROJECT_SUCCESS, projects: response.data });
  } catch (error) {
    yield put({ type: DELETE_PROJECT_FAILED, message: error.message });
  }
}

function* setProjectId(action) {
  try {
    yield put({
      type: SET_SELECTED_PROJECT_SUCCESS,
      projectID: action.projectId,
      ProjectName: action?.projectName,
    });
  } catch (error) {}
}

function* getProjectByListingIds(action) {
  const {
    deviceId,
    deviceName,
    locationId,
    locationName,
    projectId,
    projectName,
  } = action;
  try {
    yield put({
      type: SET_SELECTED_PROJECT_ID_BY_LISTING_SUCCESS,
      deviceId: deviceId || "",
      deviceName: deviceName || "",
      locationId: locationId || "",
      locationName: locationName || "",
      projectId: projectId || "",
      projectName: projectName || "",
    });
  } catch (error) {}
}

function* projectsSaga() {
  yield takeLatest(GET_ALL_PROJECTS_REQUEST, getAllProjects);
  yield takeLatest(GET_PROJECT_REQUEST, getProject);
  yield takeLatest(ADD_PROJECT_REQUEST, addProject);
  yield takeLatest(UPDATE_PROJECT_REQUEST, updateProject);
  yield takeLatest(DELETE_PROJECT_REQUEST, deleteProject);
  yield takeLatest(SET_SELECTED_PROJECT, setProjectId);
  yield takeLatest(SET_SELECTED_PROJECT_ID_BY_LISTING, getProjectByListingIds);
}

export default projectsSaga;
