import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_ENTITY_FAILED,
  ADD_ENTITY_REQUEST,
  ADD_ENTITY_SUCCESS,
  DELETE_ENTITY_FAILED,
  DELETE_ENTITY_REQUEST,
  DELETE_ENTITY_SUCCESS,
  GET_ENTITY_FAILED,
  GET_ENTITY_REQUEST,
  GET_ENTITY_SUCCESS,
  UPDATE_ENTITY_FAILED,
  UPDATE_ENTITY_REQUEST,
  UPDATE_ENTITY_SUCCESS,
} from "../../constants/ActionTypes";
import {
  addEntityAPI,
  getEntityAPI,
  updateEntityAPI,
  deleteEntityAPI,
} from "../API/Entities";

function* getEntity(action) {
  try {
    const response = yield call(getEntityAPI);
    yield put({ type: GET_ENTITY_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: GET_ENTITY_FAILED, message: error.message });
  }
}

function* addEntity(action) {
  try {
    const response = yield call(addEntityAPI);
    yield put({ type: ADD_ENTITY_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: ADD_ENTITY_FAILED, message: error.message });
  }
}

function* updateEntity(action) {
  try {
    const response = yield call(updateEntityAPI);
    yield put({ type: UPDATE_ENTITY_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: UPDATE_ENTITY_FAILED, message: error.message });
  }
}

function* deleteEntity(action) {
  try {
    const response = yield call(deleteEntityAPI);
    yield put({ type: DELETE_ENTITY_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: DELETE_ENTITY_FAILED, message: error.message });
  }
}

function* entitiesSaga() {
  yield takeLatest(GET_ENTITY_REQUEST, getEntity);
  yield takeLatest(ADD_ENTITY_REQUEST, addEntity);
  yield takeLatest(UPDATE_ENTITY_REQUEST, updateEntity);
  yield takeLatest(DELETE_ENTITY_REQUEST, deleteEntity);
}

export default entitiesSaga;
