import axiosInstance from "./axios";

const DASHBOARD_API = "/api/dashboard";
const MAINTAINANCE_API = "/api/maintain/";
const GET_ALERT = "pub/alert/";

export const getAnalyticsAPI = (companyId) => {
  return axiosInstance.get(DASHBOARD_API + `?companyId=${companyId}`);
};

export const getAlertsByMonthAPI = async (param) => {
  try {
    return await axiosInstance.get(
      DASHBOARD_API + `/alerts-by-month?${param || ""}`
    );
  } catch (error) {
    return error;
  }
};

export const getEventsByMonthAPI = async (param) => {
  try {
    return await axiosInstance.get(
      DASHBOARD_API + `/events-by-month?${param || ""}`
    );
  } catch (error) {
    return error;
  }
};

export const getMaintenanceOverdueAPI = async (param) => {
  try {
    return await axiosInstance.get(MAINTAINANCE_API + `${param}`);
  } catch (error) {
    return error;
  }
};

export const getAlertsAPI = async (param) => {
  try {
    return await axiosInstance.get(DASHBOARD_API + `${param}`);
  } catch (error) {
    return error;
  }
};

export const addMaintenanceAPI = async (body) => {
  try {
    return await axiosInstance.post(MAINTAINANCE_API, body);
  } catch (error) {
    return error;
  }
};

export const getMaintenanceAPI = async (param, body) => {
  try {
    return await axiosInstance.get(MAINTAINANCE_API + `${param || ""}`, body);
  } catch (error) {
    return error;
  }
};

export const getAlert = async (id) => {
  try {
    const res = await axiosInstance.get(GET_ALERT + id);
    return res;
  } catch (error) {
    return error;
  }
};

export const downloadFile = (parm) => {
  axiosInstance
    .get(`${DASHBOARD_API}${parm}`, {
      method: "GET",
      responseType: "blob", // important
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
};
