import axiosInstance from "./axios";

// export const getAllProjectsAPI = (param) => {
//   return axiosInstance.get(`/api/project` + `${param || ""}`);
// };

// export const getProjectAPI = () => {
//   return axiosInstance.get(`/api/project`);
// };

// export const addProjectAPI = (payload) => {
//   delete payload._id;
//     payload.location = {
//     coordinates: [payload.latitude, payload.longitude],
//   };
//   delete payload.latitude;
//   delete payload.longitude;
//   return axiosInstance.post(`/api/project`, payload);
// };

// export const updateProjectAPI = (payload) => {
//   //TODO:need improvement. The delete key code need to move somewhere else
//   let projectId = payload._id;
//   // delete payload._id;
//   // delete payload.__v;
//   // delete payload.isDeleted;
//   return axiosInstance.put(`/api/project/${projectId}`, payload);
// };

// export const deleteProjectAPI = (projectId) => {
//   return axiosInstance.delete(`/api/project/${projectId}`);
// };
const PROJECT_API = "/api/project";
const RECENT_ALERT_API = "/api/dashboard/recent-alerts";
const PROJECT_DETAILS = "/api/project/details";
const PROJECT_DETAIL_DOWNLOAD = "/api/project/details";
const PROJECT_LIST_DOWNLOAD = "/api/project/list";
const DASHBOARD_API = "/api/dashboard";
const DOWNLOAD_COMP_NEW = "/api/dashboard/device-report"

export const getAllProjectsAPI = async (param) => {
  try {
    const response = await axiosInstance.get(
      PROJECT_API + `?${param?.payload || ""}`
    );
    return response;
  } catch (error) {
    return await error;
  }
};

export const getProjectAPI = async (params) => {
  try {
    return await axiosInstance.get(PROJECT_API, { params });
  } catch (error) {
    return await error;
  }
};

export const getProjectById = async (_id) => {
  try {
    const response = await axiosInstance.get(PROJECT_API + `/${_id}`);
    return response;
  } catch (error) {
    return await error;
  }
};

export const addProjectAPI = async (body) => {
  try {
    const response = await axiosInstance.post(PROJECT_API, body);
    return response;
  } catch (error) {
    return await error;
  }
};

export const updateProjectAPI = async (_id, body) => {
  try {
    return await axiosInstance.put(PROJECT_API + `/${_id}`, body);
  } catch (error) {
    return await error;
  }
};

export const deleteProjectAPI = async (_id) => {
  try {
    return await axiosInstance.delete(PROJECT_API + `/${_id}`);
  } catch (error) {
    return await error;
  }
};

export const getRecentAlerts = async (param) => {
  try {
    const response = await axiosInstance.get(
      RECENT_ALERT_API + `${param || ""}`
    );
    return response;
  } catch (error) {
    return await error;
  }
};

export const getProjectDetails = async (param) => {
  try {
    const response = await axiosInstance.get(PROJECT_DETAILS + `${param}`);
    return response;
  } catch (error) {
    return await error;
  }
};

export const getProjectCountAPI = (param) => {
  return axiosInstance.get(DASHBOARD_API + `${param}`);
};

export const downloadProjectFile = (parm) => {
  axiosInstance
    .get(`${PROJECT_DETAIL_DOWNLOAD}${parm}`, {
      method: "GET",
      responseType: "blob", // important
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
};
export const downloadProjectList = (parm) => {
  axiosInstance
    .get(`${PROJECT_LIST_DOWNLOAD}${parm}`, {
      method: "GET",
      responseType: "blob", // important
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
};
export const downloadCompanyIdsNew = (params) => {
  axiosInstance
    .get(`${DOWNLOAD_COMP_NEW}`, {
      method: "GET",
      responseType: "blob",
      params // important
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
};

export const getProjectsForUserAPI = (params) =>
  axiosInstance.get(PROJECT_API, { params: { ...params, limit: 1000 } });