import {
  CREATE_ALERT_REQUEST,
  DELETE_ALERT_REQUEST,
  GET_ALERTS_BY_TIME_REQUEST,
  GET_ALL_ALERTS_REQUEST,
  UPDATE_ALERT_REQUEST,
} from "../../constants/ActionTypes";

export const getAlertsByTime = () => {
  return { type: GET_ALERTS_BY_TIME_REQUEST };
};

export const getAllAlerts = () => {
  return { type: GET_ALL_ALERTS_REQUEST };
};

export const createAlert = () => {
  return { type: CREATE_ALERT_REQUEST };
};

export const updateAlert = () => {
  return { type: UPDATE_ALERT_REQUEST };
};

export const deleteAlert = () => {
  return { type: DELETE_ALERT_REQUEST };
};
