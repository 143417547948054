import { put, takeLatest } from "redux-saga/effects";
import {
  SET_SELECTED_LOCATION,
  SET_SELECTED_LOCATION_SUCCESS,
} from "../../constants/ActionTypes";

function* setLocationId(action) {
  try {
    yield put({
      type: SET_SELECTED_LOCATION_SUCCESS,
      locationID: action.locationId,
      locationName: action?.locationName,
    });
  } catch (error) {}
}

function* locationSaga() {
  yield takeLatest(SET_SELECTED_LOCATION, setLocationId);
}

export default locationSaga;
