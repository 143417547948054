import {
  ADD_PROJECT_FAILED,
  ADD_PROJECT_REQUEST,
  ADD_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  GET_ALL_PROJECTS_FAILED,
  GET_ALL_PROJECTS_REQUEST,
  GET_ALL_PROJECTS_SUCCESS,
  GET_PROJECT_FAILED,
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  SET_SELECTED_PROJECT,
  SET_SELECTED_PROJECT_ID_BY_LISTING,
  SET_SELECTED_PROJECT_ID_BY_LISTING_SUCCESS,
  SET_SELECTED_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILED,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  error: false,
  projects: [],
};

const ProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PROJECTS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_ALL_PROJECTS_SUCCESS: {
      return { ...state, projects: action?.projects, loading: false };
    }
    case GET_ALL_PROJECTS_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case GET_PROJECT_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_PROJECT_SUCCESS: {
      return { ...state, loading: true };
    }
    case GET_PROJECT_FAILED: {
      return { ...state, loading: true };
    }

    case ADD_PROJECT_REQUEST: {
      return { ...state, loading: true };
    }
    case ADD_PROJECT_SUCCESS: {
      return { ...state, loading: true };
    }
    case ADD_PROJECT_FAILED: {
      return { ...state, loading: true };
    }
    case UPDATE_PROJECT_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_PROJECT_SUCCESS: {
      return { ...state, loading: true };
    }
    case UPDATE_PROJECT_FAILED: {
      return { ...state, loading: true };
    }
    case DELETE_PROJECT_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_PROJECT_SUCCESS: {
      return { ...state, loading: true };
    }
    case DELETE_PROJECT_FAILED: {
      return { ...state, loading: true };
    }
    case SET_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProjectId: action.projectId,
        selectProjectName: action?.projectName,
      };
    }
    case SET_SELECTED_PROJECT_SUCCESS: {
      return { ...state, loading: true };
    }
    case SET_SELECTED_PROJECT_ID_BY_LISTING: {
      const {
        deviceId,
        deviceName,
        locationId,
        locationName,
        projectId,
        projectName,
      }= action
      return {
        ...state,
        deviceId: deviceId || "",
        deviceName: deviceName || "",
        locationId: locationId || "",
        locationName: locationName || "",
        projectId: projectId || "",
        projectName: projectName || "",
      };
    }
    case SET_SELECTED_PROJECT_ID_BY_LISTING_SUCCESS: {
      return { ...state, loading: true };
    }
    default:
      return state;
  }
};

export default ProjectReducer;
