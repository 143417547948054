import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_ALERT_FAILED,
  CREATE_ALERT_REQUEST,
  CREATE_ALERT_SUCCESS,
  DELETE_ALERT_FAILED,
  DELETE_ALERT_REQUEST,
  DELETE_ALERT_SUCCESS,
  GET_ALERTS_BY_TIME_FAILED,
  GET_ALERTS_BY_TIME_REQUEST,
  GET_ALERTS_BY_TIME_SUCCESS,
  GET_ALL_ALERTS_FAILED,
  GET_ALL_ALERTS_REQUEST,
  GET_ALL_ALERTS_SUCCESS,
  UPDATE_ALERT_REQUEST,
} from "../../constants/ActionTypes";
import {
  createAlertAPI,
  getAlertsByTimeAPI,
  updateAlertAPI,
  deleteAlertAPI,
  getAllAlertsAPI,
} from "../API/Alerts";

function* getAlertsByTime(action) {
  try {
    const response = yield call(getAlertsByTimeAPI);
    yield put({ type: GET_ALERTS_BY_TIME_SUCCESS, alerts: response.data });
  } catch (error) {
    yield put({ type: GET_ALERTS_BY_TIME_FAILED, message: error.message });
  }
}


function* getAllAlerts(action) {
  try {
    const response = yield call(getAllAlertsAPI);
    yield put({ type: GET_ALL_ALERTS_SUCCESS, alerts: response.data.data });
  } catch (error) {
    yield put({ type: GET_ALL_ALERTS_FAILED, message: error.message });
  }
}
function* createAlert(action) {
  try {
    const response = yield call(createAlertAPI);
    yield put({ type: CREATE_ALERT_SUCCESS, alerts: response.data });
  } catch (error) {
    yield put({ type: CREATE_ALERT_FAILED, message: error.message });
  }
}

function* updateAlert(action) {
  try {
    const response = yield call(updateAlertAPI);
    yield put({ type: CREATE_ALERT_SUCCESS, alerts: response.data });
  } catch (error) {
    yield put({ type: CREATE_ALERT_FAILED, message: error.message });
  }
}

function* deleteAlert(action) {
  try {
    const response = yield call(deleteAlertAPI);
    yield put({ type: DELETE_ALERT_SUCCESS, alerts: response.data });
  } catch (error) {
    yield put({ type: DELETE_ALERT_FAILED, message: error.message });
  }
}

function* alertsSaga() {
  yield takeLatest(GET_ALERTS_BY_TIME_REQUEST, getAlertsByTime);
  yield takeLatest(GET_ALL_ALERTS_REQUEST, getAllAlerts);
  yield takeLatest(CREATE_ALERT_REQUEST, createAlert);
  yield takeLatest(UPDATE_ALERT_REQUEST, updateAlert);
  yield takeLatest(DELETE_ALERT_REQUEST, deleteAlert);
}

export default alertsSaga;
