import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import "./style.less";

export default function AsyncDropDown({
  backgroundColor = "white",
  width = "100%",
  border = "1px solid #d9d9d9",
  height = "40px",
  minWidth,
  name,
  placeholder = "",
  ...props
}) {
  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };
  const colourStyles = {
    container: (styles) => ({
      ...styles,
      width: width,
      minWidth: minWidth,
      height: height,
    }),

    control: (styles) => ({
      ...styles,
      backgroundColor: backgroundColor,
      border: border,
      borderRadius: "5px",
      cursor: "pointer",
    }),
    singleValue: (provided, state) => {
      return { ...provided, color: "#1B2F54" };
    },

    input: (styles) => ({ ...styles }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "inherit",
      fontWeight: 400,
    }),
    // group: (styles) => ({
    //   ...styles,
    //   backgroundColor: "red",
    // }),
  };
return (
  <AsyncPaginate
    className={props?.className}
    value={props.value}
    loadOptions={props.loadOptions}
    loadOptionsOnMenuOpen={true}
    onChange={onChange}
    placeholder={placeholder}
    getOptionValue={(option) => option?._id}
    getOptionLabel={(option) => option?.[props?.labelName]}
    additional={{ page: 1 }}
    controlShouldRenderValue={props.renderValue}
    isFirstLoad={props.isFirstLoad}
    name={name}
    key={props.key}
    isDisabled={props.isDisabled}
    debounceTimeout={1000}
    styles={colourStyles}
  />
);
}
