import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Users from "./Users";
import Companies from "./Companies";
import Projects from "./Projects";
import Alerts from "./Alerts";
import Analytics from "./Analytics";
import Location from "./Location";
import Device from "./Device";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    users: Users,
    companies: Companies,
    projects: Projects,
    alerts: Alerts,
    analytics: Analytics,
    location: Location,
    device: Device,
  });

export default createRootReducer;
