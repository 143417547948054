import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        path={`${match.url}stations`}
        component={asyncComponent(() => import("./ProjectListing"))}
      />
      <Route
        path={`${match.url}project/SIFJB`}
        component={asyncComponent(() => import("./ProjectDetails"))}
      />
      <Route
        path={`${match.url}locations`}
        component={asyncComponent(() => import("./Locations"))}
      />
      <Route
        path={`${match.url}users`}
        component={asyncComponent(() => import("./Users"))}
      />
      <Route
        path={`${match.url}assets`}
        component={asyncComponent(() => import("./Assets"))}
      />
      {/* <Route
        path={`${match.url}companies`}
        component={asyncComponent(() => import("./Companiesold"))}
      /> */}
      <Route
        path={`${match.url}company`}
        component={asyncComponent(() => import("./CompanyListing"))}
      />
      <Route
        path={`${match.url}settings`}
        component={asyncComponent(() => import("./Settings"))}
      />
      <Route
        path={`${match.url}user`}
        component={asyncComponent(() => import("./UserListing"))}
      />
      <Route
        path={`${match.url}device-type`}
        component={asyncComponent(() => import("./DeviceType"))}
      />
      <Route
        path={`${match.url}location`}
        component={asyncComponent(() => import("./LocationBox"))}
      />
      <Route
        path={`${match.url}maintenance`}
        component={asyncComponent(() => import("./MaintenanceReport"))}
      />
      <Route
        path={`${match.url}alert-warning`}
        component={asyncComponent(() => import("./AlertWarning"))}
      />
      <Route
        path={`${match.url}device`}
        component={asyncComponent(() => import("./ActiveDevices"))}
      />
      <Route
        path={`${match.url}card-type`}
        component={asyncComponent(() => import("./CardTypes"))}
      />
      <Route
        path={`${match.url}sub-card-type`}
        component={asyncComponent(() => import("./SubCardTypes"))}
      />
      <Route
        path={`${match.url}card`}
        component={asyncComponent(() => import("./Card"))}
      />
      <Route
        path={`${match.url}assign-card`}
        component={asyncComponent(() => import("./AssignCard"))}
      />
      <Route
        path={`${match.url}configure-card`}
        component={asyncComponent(() => import("./ConfigureCard"))}
      />
      <Route
        path={`${match.url}device-config`}
        component={asyncComponent(() => import("./DeviceConfig"))}
      />
      <Route
        path={`${match.url}notification`}
        component={asyncComponent(() => import("./Notification"))}
      />
    </Switch>
  </div>
);

export default App;
