// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SHOW_SIGN_OUT_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

//Users------------------------------------------------------------------

// Get device name & id
export const SET_SELECTED_DEVICE = "SET_SELECTED_DEVICE";
export const SET_SELECTED_DEVICE_SUCCESS = "SET_SELECTED_DEVICE_SUCCESS";

// Get location name & id
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const SET_SELECTED_LOCATION_SUCCESS = "SET_SELECTED_LOCATION_SUCCESS";

//Get all device type
export const GET_ALL_DEVICE_TYPE_REQUEST = "GET_ALL_DEVICE_TYPE_REQUEST";
export const GET_ALL_DEVICE_TYPE_SUCCESS = "GET_ALL_DEVICE_TYPE_SUCCESS";
export const GET_ALL_DEVICE_TYPE_FAILED = "GET_ALL_DEVICE_TYPE_FAILED";
export const SELECT_DEVICE_TYPE = "SELECT_DEVICE_TYPE";

//Get device type
export const GET_DEVICE_TYPE_REQUEST = "GET_DEVICE_TYPE_REQUEST";
export const GET_DEVICE_TYPE_SUCCESS = "GET_DEVICE_TYPE_SUCCESS";
export const GET_DEVICE_TYPE_FAILED = "GET_DEVICE_TYPE_FAILED";

//Add device type
export const ADD_DEVICE_TYPE_REQUEST = "ADD_DEVICE_TYPE_REQUEST";
export const ADD_DEVICE_TYPE_SUCCESS = "ADD_DEVICE_TYPE_SUCCESS";
export const ADD_DEVICE_TYPE_FAILED = "ADD_DEVICE_TYPE_FAILED";

//Update device type
export const UPDATE_DEVICE_TYPE_REQUEST = "UPDATE_DEVICE_TYPE_REQUEST";
export const UPDATE_DEVICE_TYPE_SUCCESS = "UPDATE_DEVICE_TYPE_SUCCESS";
export const UPDATE_DEVICE_TYPE_FAILED = "UPDATE_DEVICE_TYPE_FAILED";

//Delete device type
export const DELETE_DEVICE_TYPE_REQUEST = "DELETE_DEVICE_TYPE_REQUEST";
export const DELETE_DEVICE_TYPE_SUCCESS = "DELETE_DEVICE_TYPE_SUCCESS";
export const DELETE_DEVICE_TYPE_FAILED = "DELETE_DEVICE_TYPE_FAILED";

//Get all users
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";

//Get user
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

//Add user
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";

//Update user
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

//Delete user
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

//User logins------------------------------------------------------------------

//Get by Id
export const GET_USER_LOGIN_BY_ID_REQUEST = "GET_USER_LOGIN_BY_ID_REQUEST";
export const GET_USER_LOGIN_BY_ID_SUCCESS = "GET_USER_LOGIN_BY_ID_SUCCESS";
export const GET_USER_LOGIN_BY_ID_FAILED = "GET_USER_LOGIN_BY_ID_FAILED";

//Get user logins
export const GET_USER_LOGIN_REQUEST = "GET_USER_LOGIN_REQUEST";
export const GET_USER_LOGIN_SUCCESS = "GET_USER_LOGIN_SUCCESS";
export const GET_USER_LOGIN_FAILED = "GET_USER_LOGIN_FAILED";

//Add user logins
export const ADD_USER_LOGIN_REQUEST = "ADD_USER_LOGIN_REQUEST";
export const ADD_USER_LOGIN_SUCCESS = "ADD_USER_LOGIN_SUCCESS";
export const ADD_USER_LOGIN_FAILED = "ADD_USER_LOGIN_FAILED";

//Update user logins
export const UPDATE_USER_LOGIN_REQUEST = "UPDATE_USER_LOGIN_REQUEST";
export const UPDATE_USER_LOGIN_SUCCESS = "UPDATE_USER_LOGIN_SUCCESS";
export const UPDATE_USER_LOGIN_FAILED = "UPDATE_USER_LOGIN_FAILED";

//Delete user logins
export const DELETE_USER_LOGIN_REQUEST = "DELETE_USER_LOGIN_REQUEST";
export const DELETE_USER_LOGIN_SUCCESS = "DELETE_USER_LOGIN_SUCCESS";
export const DELETE_USER_LOGIN_FAILED = "DELETE_USER_LOGIN_FAILED";

//Company-------------------------------------------------------------------

//Get all companies
export const GET_ALL_COMPANIES_REQUEST = "GET_ALL_COMPANIES_REQUEST";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_FAILED = "GET_ALL_COMPANIES_FAILED";

//Get company
export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILED = "GET_COMPANY_FAILED";

//Add company
export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILED = "ADD_COMPANY_FAILED";

//Update company
export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILED = "UPDATE_COMPANY_FAILED";

//Delete company
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILED = "DELETE_COMPANY_FAILED";

export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const SET_SELECTED_COMPANY_SUCCESS = "SET_SELECTED_COMPANY_SUCCESS";

//Project-------------------------------------------------------------------

//Get all projects
export const GET_ALL_PROJECTS_REQUEST = "GET_ALL_PROJECTS_REQUEST";
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";
export const GET_ALL_PROJECTS_FAILED = "GET_ALL_PROJECTS_FAILED";

//Get project
export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILED = "GET_PROJECT_FAILED";

//Add project
export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAILED = "ADD_PROJECT_FAILED";

//Update project
export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILED = "UPDATE_PROJECT_FAILED";

//Delete project
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILED = "DELETE_PROJECT_FAILED";

export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const SET_SELECTED_PROJECT_SUCCESS = "SET_SELECTED_PROJECT_SUCCESS";

export const SET_SELECTED_PROJECT_ID_BY_LISTING = "SET_SELECTED_PROJECT_ID_BY_LISTING";
export const SET_SELECTED_PROJECT_ID_BY_LISTING_SUCCESS = "SET_SELECTED_PROJECT_ID_BY_LISTING_SUCCESS";

//Entity-------------------------------------------------------------------

//Get Entity
export const GET_ENTITY_REQUEST = "GET_ENTITY_REQUEST";
export const GET_ENTITY_SUCCESS = "GET_ENTITY_SUCCESS";
export const GET_ENTITY_FAILED = "GET_ENTITY_FAILED";

//Add Entity
export const ADD_ENTITY_REQUEST = "ADD_ENTITY_REQUEST";
export const ADD_ENTITY_SUCCESS = "ADD_ENTITY_SUCCESS";
export const ADD_ENTITY_FAILED = "ADD_ENTITY_FAILED";

//Update Entity
export const UPDATE_ENTITY_REQUEST = "UPDATE_ENTITY_REQUEST";
export const UPDATE_ENTITY_SUCCESS = "UPDATE_ENTITY_SUCCESS";
export const UPDATE_ENTITY_FAILED = "UPDATE_ENTITY_FAILED";

//Delete Entity
export const DELETE_ENTITY_REQUEST = "DELETE_ENTITY_REQUEST";
export const DELETE_ENTITY_SUCCESS = "DELETE_ENTITY_SUCCESS";
export const DELETE_ENTITY_FAILED = "DELETE_ENTITY_FAILED";

//Alert-------------------------------------------------------------------

//Get Alerts by time
export const GET_ALERTS_BY_TIME_REQUEST = "GET_ALERTS_BY_TIME_REQUEST";
export const GET_ALERTS_BY_TIME_SUCCESS = "GET_ALERTS_BY_TIME_SUCCESS";
export const GET_ALERTS_BY_TIME_FAILED = "GET_ALERTS_BY_TIME_FAILED";

export const GET_ALL_ALERTS_REQUEST = "GET_ALL_ALERTS_REQUEST";
export const GET_ALL_ALERTS_SUCCESS = "GET_ALL_ALERTS_SUCCESS";
export const GET_ALL_ALERTS_FAILED = "GET_ALL_ALERTS_FAILED";

//Create Alert
export const CREATE_ALERT_REQUEST = "CREATE_ALERT_REQUEST";
export const CREATE_ALERT_SUCCESS = "CREATE_ALERT_SUCCESS";
export const CREATE_ALERT_FAILED = "CREATE_ALERT_FAILED";

//Update Alert
export const UPDATE_ALERT_REQUEST = "UPDATE_ALERT_REQUEST";
export const UPDATE_ALERT_SUCCESS = "UPDATE_ALERT_SUCCESS";
export const UPDATE_ALERT_FAILED = "UPDATE_ALERT_FAILED";

//Delete Alert
export const DELETE_ALERT_REQUEST = "DELETE_ALERT_REQUEST";
export const DELETE_ALERT_SUCCESS = "DELETE_ALERT_SUCCESS";
export const DELETE_ALERT_FAILED = "DELETE_ALERT_FAILED";

//Events-------------------------------------------------------------------

//Get Events by time
export const GET_EVENTS_BY_TIME_REQUEST = "GET_EVENTS_BY_TIME_REQUEST";
export const GET_EVENTS_BY_TIME_SUCCESS = "GET_EVENTS_BY_TIME_SUCCESS";
export const GET_EVENTS_BY_TIME_FAILED = "GET_EVENTS_BY_TIME_FAILED";

//Create Event
export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILED = "CREATE_EVENT_FAILED";

//Update Event
export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILED = "UPDATE_EVENT_FAILED";

//Delete Event
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";

//Analytics-----------------------------------------------------------------

//Get Dashboard Analytics
export const GET_ANALYTICS_REQUEST = "GET_ANALYTICS_REQUEST";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_FAILED = "GET_ANALYTICS_FAILED";
