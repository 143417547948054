import React, { useEffect } from "react";
import { Button, Form, Input, message, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { hideMessage, showAuthLoader, userSignIn } from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (alertMessage?.message) {
      message.error(alertMessage?.message.toString());
    } else if (alertMessage) {
      message.success(alertMessage.toString());
    }
  }, [showMessage, alertMessage]);

  useEffect(() => {
    if ((showMessage && alertMessage) || (!showMessage && alertMessage)) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null && authUser !== "") {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [authUser, alertMessage, showMessage]);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="gx-app-login-wrap gx-bg-primary">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          {/* <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div> */}
          <div className="gx-app-login-content">
            {/* <img src={"/assets/images/logo-white.png"} alt="Neature" /> */}
            <img
              height={100}
              // width={400}
              src={"/assets/images/Cairosiot_logo.png"}
              alt="Neature"
              style={{ padding: "20px" }}
              className="gx-mb-2"
            />

            <h2 className="title gx-mb-4 titleHead">Login Account</h2>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0 "
            >
              <Typography.Text className="typography">Email</Typography.Text>
              <Form.Item
                // initialValue="jarsservices709@gmail.com"
                rules={[
                  { required: true, message: "The input is not valid E-mail!" },
                ]}
                name="email"
              >
                <Input />
              </Form.Item>
              <Typography.Text className="typography">Password</Typography.Text>
              <Form.Item
                // initialValue="Enableit@123"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                className="gx-m-0"
                name="password"
              >
                <Input.Password type="password" />
              </Form.Item>
              {/* <Form.Item>
                <Checkbox>
                  <IntlMessages id="appModule.iAccept" />
                </Checkbox>
                <span className="gx-signup-form-forgot gx-link">
                  <IntlMessages id="appModule.termAndCondition" />
                </span>
              </Form.Item> */}
              <Form.Item>
                <Link to={"/forgot-password"} className="gx-link">
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Link>
              </Form.Item>
              <Form.Item className="gx-text-center">
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.login" />
                </Button>
                {/* <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signup">
                  <IntlMessages id="app.userAuth.signUp" />
                </Link> */}
              </Form.Item>
              {/* <div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <FacebookOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userFacebookSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <GithubOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGithubSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <TwitterOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userTwitterSignIn());
                      }}
                    />
                  </li>
                </ul>
              </div> */}
              {/* <span className="gx-text-light gx-fs-sm">
                {" "}
                demo user email: 'demo@example.com' and password: 'demo#123'
              </span> */}
            </Form>
          </div>
          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
