import { put, takeLatest } from "redux-saga/effects";
import {
    SET_SELECTED_DEVICE,
  SET_SELECTED_DEVICE_SUCCESS,
} from "../../constants/ActionTypes";

function* setDeviceId(action) {
  try {
    yield put({
      type: SET_SELECTED_DEVICE_SUCCESS,
      deviceID: action.deviceId,
      deviceName: action?.deviceName,
    });
  } catch (error) {}
}

function* deviceSaga() {
  yield takeLatest(SET_SELECTED_DEVICE, setDeviceId);
}

export default deviceSaga;
