import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COMPANY_FAILED,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILED,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  GET_ALL_COMPANIES_FAILED,
  GET_ALL_COMPANIES_REQUEST,
  GET_ALL_COMPANIES_SUCCESS,
  GET_COMPANY_FAILED,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  SET_SELECTED_COMPANY,
  SET_SELECTED_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
} from "../../constants/ActionTypes";
import {
  addCompanyAPI,
  getCompanyAPI,
  getAllCompaniesAPI,
  updateCompanyAPI,
  deleteCompanyAPI,
} from "../API/Companies";

function* getAllCompanies(action) {
  try {
    const response = yield call(getAllCompaniesAPI, action);
    if (response?.data?.status === 200) {
      yield put({
        type: GET_ALL_COMPANIES_SUCCESS,
        companies: response?.data,
        loading: false,
      });
    }
  } catch (error) {
    yield put({ type: GET_ALL_COMPANIES_FAILED, message: error.message });
  }
}

function* getCompany(action) {
  try {
    const response = yield call(getCompanyAPI);
    yield put({ type: GET_COMPANY_SUCCESS, companies: response.data });
  } catch (error) {
    yield put({ type: GET_COMPANY_FAILED, message: error.message });
  }
}

function* addCompany(action) {
  try {
    const response = yield call(addCompanyAPI, action.payload);
    yield put({ type: ADD_COMPANY_SUCCESS, companies: response.data });
  } catch (error) {
    yield put({ type: ADD_COMPANY_FAILED, message: error.message });
  }
}

function* updateCompany(action) {
  try {
    const response = yield call(updateCompanyAPI, action.payload);
    yield put({ type: UPDATE_COMPANY_SUCCESS, companies: response.data });
  } catch (error) {
    yield put({ type: UPDATE_COMPANY_FAILED, message: error.message });
  }
}

function* deleteCompany(action) {
  try {
    const response = yield call(deleteCompanyAPI, action.payload);
    yield put({ type: DELETE_COMPANY_SUCCESS, companies: response.data });
  } catch (error) {
    yield put({ type: DELETE_COMPANY_FAILED, message: error.message });
  }
}

function* setCompanyId(action) {
  try {
    yield put({
      type: SET_SELECTED_COMPANY_SUCCESS,
      companies: action.companyId,
      companyName: action?.companyName,
    });
  } catch (error) {}
}

function* companiesSaga() {
  yield takeLatest(GET_ALL_COMPANIES_REQUEST, getAllCompanies);
  yield takeLatest(GET_COMPANY_REQUEST, getCompany);
  yield takeLatest(ADD_COMPANY_REQUEST, addCompany);
  yield takeLatest(UPDATE_COMPANY_REQUEST, updateCompany);
  yield takeLatest(DELETE_COMPANY_REQUEST, deleteCompany);
  yield takeLatest(SET_SELECTED_COMPANY, setCompanyId);
}

export default companiesSaga;
