import axios from "axios";
import axiosInstance from "./axios";

const CHANGE_PASSWORD = "/api/auth/change-password";
const FORGOT_PASSWORD = "/pub/forgot-password";
const SET_PASSWORD = "/pub/set-password";
const LOG_OUT_API = "/api/auth/logout";

export const getUserLoginByIdAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const getUserLoginAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const addUserLoginAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const updateUserLoginAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const deleteUserLoginAPI = () => {
  return axios.get("https://gorest.co.in/public/v2/users");
};

export const LoginApi = (body) => {
  return axiosInstance.post("/pub/login", body);
};

export const ChangePasswordApi = async (body) => {
  try {
    return await axiosInstance.post(CHANGE_PASSWORD, body);
  } catch (error) {
    return await error;
  }
};

export const ForgotPasswordApi = async (body) => {
  try {
    return await axiosInstance.post(FORGOT_PASSWORD, body);
  } catch (error) {
    return await error;
  }
};

export const SetPasswordApi = async (body) => {
  try {
    return await axiosInstance.post(SET_PASSWORD, body);
  } catch (error) {
    return await error;
  }
};

export const LogOutApi = async (body) => {
  try {
    return await axiosInstance.post(LOG_OUT_API, body);
  } catch (error) {
    return await error;
  }
};
