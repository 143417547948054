import {
  CREATE_ALERT_FAILED,
  CREATE_ALERT_REQUEST,
  CREATE_ALERT_SUCCESS,
  DELETE_ALERT_FAILED,
  DELETE_ALERT_REQUEST,
  DELETE_ALERT_SUCCESS,
  GET_ALERTS_BY_TIME_FAILED,
  GET_ALERTS_BY_TIME_REQUEST,
  GET_ALERTS_BY_TIME_SUCCESS,
  GET_ALL_ALERTS_FAILED,
  GET_ALL_ALERTS_REQUEST,
  GET_ALL_ALERTS_SUCCESS,
  UPDATE_ALERT_FAILED,
  UPDATE_ALERT_REQUEST,
  UPDATE_ALERT_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  error: false,
  alerts: [],
};

const AlertReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALERTS_BY_TIME_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_ALERTS_BY_TIME_SUCCESS: {
      return { ...state, users: action.alerts, loading: false };
    }
    case GET_ALERTS_BY_TIME_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case GET_ALL_ALERTS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_ALL_ALERTS_SUCCESS: {
      return { ...state, alerts: action.alerts, loading: false };
    }
    case GET_ALL_ALERTS_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case CREATE_ALERT_REQUEST: {
      return { ...state, loading: true };
    }
    case CREATE_ALERT_SUCCESS: {
      return { ...state, loading: true };
    }
    case CREATE_ALERT_FAILED: {
      return { ...state, loading: true };
    }

    case UPDATE_ALERT_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_ALERT_SUCCESS: {
      return { ...state, loading: true };
    }
    case UPDATE_ALERT_FAILED: {
      return { ...state, loading: true };
    }

    case DELETE_ALERT_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_ALERT_SUCCESS: {
      return { ...state, loading: true };
    }
    case DELETE_ALERT_FAILED: {
      return { ...state, loading: true };
    }

    default:
      return state;
  }
};

export default AlertReducer;
