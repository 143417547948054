import React, { useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const imageSize = { width: 10, height: 10 };

const PDF = ({ companyReportData }) => {
  const generatePDF = () => {
    if (Object.keys(companyReportData)?.length > 0) {
      const doc = new jsPDF();

      // Add text
      const pageWidth = doc.internal.pageSize.getWidth();
      // const pageHeight = doc.internal.pageSize.getHeight();

      const titleText = "Hello!";
      const titleFontSize = 16;

      const textWidth =
        (doc.getStringUnitWidth(titleText) * titleFontSize) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;

      // Add centered text
      doc.setFontSize(titleFontSize);
      doc.text(companyReportData?.locationBoxes?.[0]?.companyName, 10, 10);

      doc.setFontSize(12);
      doc.text(
        `${companyReportData?.startDate} - ${companyReportData?.endDate}`,
        153,
        10
      );

      // DRAW LINE
      doc.setDrawColor(169, 169, 169);
      doc.setLineWidth(0.5);
      doc.line(5, 18, 205, 18); // x1 y1 x2 y2

      //Card Content
      const cardWidth = 40;
      const cardHeight = 30;
      const cardY = 25;
      const cardSpacing = 10;
      const borderRadius = 5;

      const cards = [
        {
          backgroundColor: "#b3b3ff",
          content: "Stations",
          count: companyReportData?.counts?.projects?.toString() || "0",
          image: "/assets/images/station.png",
        },
        {
          backgroundColor: "#ccddff",
          content: "Devices",
          count: companyReportData?.counts?.devices?.toString() || "0",
          image: "/assets/images/devices1.png",
        },
        {
          backgroundColor: "#ffe6e6",
          content: "Alerts",
          count: companyReportData?.counts?.alerts?.toString() || "0",
          image: "/assets/images/alert.png",
        },
        {
          backgroundColor: "#ffe6cc",
          content: "Warnings",
          count: companyReportData?.counts?.warnings?.toString() || "0",
          image: "/assets/images/warning.png",
        },
      ];

      // Draw cards
      cards.forEach((card, index) => {
        const cardX = 10 + (cardWidth + cardSpacing) * index;

        // Draw card background with border radius
        doc.setFillColor(card.backgroundColor);
        doc.roundedRect(
          cardX,
          cardY,
          cardWidth,
          cardHeight,
          borderRadius,
          borderRadius,
          "F"
        );

        // Add text content
        doc.setTextColor("#333333");
        doc.setFontSize(12);
        const contentLines = doc.splitTextToSize(card.content, cardWidth - 10);
        doc.text(contentLines, cardX + 5, cardY + 10);

        //Add image
        if (card.image) {
          const imgX = cardX + 25;
          const imgY = cardY + 5;
          doc.addImage(
            card.image,
            "JPEG",
            imgX,
            imgY,
            imageSize.width,
            imageSize.height
          );
        }

        // Add count below content
        doc.setFontSize(15);
        const countX = cardX + cardWidth / 2;
        const countY = cardY + cardHeight - 5; // Adjust Y position for count text
        doc.text(card.count, countX, countY, null, null, "center");
      });

      // DRAW LINE
      doc.setDrawColor(169, 169, 169);
      doc.setLineWidth(0.5); // Line width
      doc.line(5, 60, 205, 60); // x1 y1 x2 y2

      doc.setFontSize(16);
      doc.text("Location Details", textX, 75);

      // Define table columns and rows
      const columns = [
        "Station",
        "Location Box",
        "No. of Devices",
        "No. of Alerts",
        "No. of Warnings",
      ];

      const data = companyReportData?.locationBoxes?.map((item) => {
        return [
          item?.name,
          item?.locationBox,
          item?.noOfDevices?.toString(),
          item?.noOfAlert?.toString(),
          item?.noOfWarning?.toString(),
        ];
      });
      const rows = data;

      // Add table
      // doc.autoTable(columns, rows, { startY: 85 });
      doc.autoTable({
        head: [columns],
        body: rows,
        // styles: { textColor: [255, 0, 0] },
        startY: 85,
        willDrawCell: (data) => {
          const { cell, column, row } = data;
          if (parseInt(row?.raw[3]) > 0) {
            doc.setTextColor("#EE7570");
          } else if (parseInt(row?.raw[4]) > 0) {
            doc.setTextColor("#ffad33");
          }
        },
      });

      // DRAW LINE
      doc.setDrawColor(169, 169, 169);
      doc.setLineWidth(0.5);
      doc.line(
        5,
        doc.autoTable.previous.finalY + 5,
        205,
        doc.autoTable.previous.finalY + 5
      ); // x1 y1 x2 y2

      doc.setFontSize(16);
      doc.text("RAG Report", textX, doc.autoTable.previous.finalY + 15);

      // RAG REPORT TABLE
      const ragReportColumns = [
        "Station",
        "Equipment Name",
        "Due Date",
        "Over Due",
      ];

      // console.log("companyReportData", companyReportData?.ragReportData);
      const ragdata = companyReportData?.ragReportData?.map((item) => {
        return [
          item?.projectName,
          item?.deviceName,
          item?.dueDate,
          item?.overDue == true ? "Over Due" : "On Time",
        ];
      });
      // console.log(ragdata, "checkkkk");

      const ragRows = ragdata;

      // Add table
      // doc.autoTable(ragReportColumns, ragRows, {
      //   startY: doc.autoTable.previous.finalY + 20,
      // });
      doc.autoTable({
        head: [ragReportColumns],
        body: ragRows,
        styles: { textColor: [255, 0, 0] },
        startY: doc.autoTable.previous.finalY + 20,
        willDrawCell: (data) => {
          const { cell, column } = data;
          // console.log(data, "7897987");
          if (column?.index === 3 && data?.section !== "head") {
            cell?.raw === "Over Due"
              ? doc.setTextColor("#ff0000")
              : doc.setTextColor("#006600");
          } else if (data?.section !== "head") doc.setTextColor(0, 0, 0);
          else {
            doc.setTextColor("#ffffff");
          }
        },
      });

      // Save the PDF
      doc.save("Company-Report.pdf");
    } else {
      console.log("ERRORRR: NO DATA");
    }
  };
  useEffect(() => {
    if (Object.keys(companyReportData)?.length > 0) {
      generatePDF();
    }
  }, [companyReportData]);

  return (
    <>
      {/* <div>
        <button onClick={generatePDF}>Download PDF</button>
      </div> */}
    </>
  );
};

export default PDF;
