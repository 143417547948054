import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_DEVICE_TYPE_FAILED,
  ADD_DEVICE_TYPE_REQUEST,
  ADD_DEVICE_TYPE_SUCCESS,
  DELETE_DEVICE_TYPE_FAILED,
  DELETE_DEVICE_TYPE_REQUEST,
  DELETE_DEVICE_TYPE_SUCCESS,
  GET_ALL_DEVICE_TYPE_FAILED,
  GET_ALL_DEVICE_TYPE_REQUEST,
  GET_ALL_DEVICE_TYPE_SUCCESS,
  GET_DEVICE_TYPE_REQUEST,
  SELECT_DEVICE_TYPE,
  UPDATE_DEVICE_TYPE_FAILED,
  UPDATE_DEVICE_TYPE_REQUEST,
  UPDATE_DEVICE_TYPE_SUCCESS,
} from "../../constants/ActionTypes";
import { getAllDeviceTypeAPI } from "../API/DeviceType";
import {
  addUserAPI,
  getAllUsersAPI,
  updateUserAPI,
  deleteUserAPI,
} from "../API/Users";

function* getAllDeviceType(action) {
  try {
    const response = yield call(getAllDeviceTypeAPI);
    // const response = getAllUSersResponse;
    yield put({ type: GET_ALL_DEVICE_TYPE_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: GET_ALL_DEVICE_TYPE_FAILED, message: error.message });
  }
}

//
function* addDeviceType(action) {
  try {
    const response = yield call(addUserAPI);
    yield put({ type: ADD_DEVICE_TYPE_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: ADD_DEVICE_TYPE_FAILED, message: error.message });
  }
}

function* updateDeviceType(action) {
  try {
    const response = yield call(updateUserAPI);
    yield put({ type: UPDATE_DEVICE_TYPE_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: UPDATE_DEVICE_TYPE_FAILED, message: error.message });
  }
}

function* deleteDeviceType(action) {
  try {
    const response = yield call(deleteUserAPI);
    yield put({ type: DELETE_DEVICE_TYPE_SUCCESS, users: response.data });
  } catch (error) {
    yield put({ type: DELETE_DEVICE_TYPE_FAILED, message: error.message });
  }
}

// function* setDeviceType(action) {
//   try {
//     yield put({
//       type: SELECT_DEVICE_TYPE,
//       ...action.payload,
//     });
//   } catch (error) {}
// }

function* deviceTypeSaga() {
  yield takeLatest(GET_ALL_DEVICE_TYPE_REQUEST, getAllDeviceType);
  yield takeLatest(GET_DEVICE_TYPE_REQUEST, getAllUsersAPI);
  yield takeLatest(ADD_DEVICE_TYPE_REQUEST, addDeviceType);
  yield takeLatest(UPDATE_DEVICE_TYPE_REQUEST, updateDeviceType);
  yield takeLatest(DELETE_DEVICE_TYPE_REQUEST, deleteDeviceType);
  // yield takeLatest(SELECT_DEVICE_TYPE, setDeviceType);
}

export default deviceTypeSaga;
