import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from "../../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  showSuccessMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
} from "../actions/Auth";
import { LoginApi, LogOutApi } from "../API/Auth";
import { SelectedCompanyId } from "../actions";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithEmailPasswordRequest = async (email, password) => {
  const loginRes = await LoginApi({
    email,
    password,
    deviceType: "web",
    version: "",
  });
  if (loginRes?.data?.status === 200) {
    const { user, accessToken, refreshToken } = loginRes?.data?.data;
    const { role } = loginRes?.data?.data?.user;
    const { message, status } = loginRes?.data;
    return { ...user, accessToken, message, status, role, refreshToken };
  } else {
    return loginRes;
  }
};

const signOutRequest = async () => {
  const response = await LogOutApi();
  return response;
};

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    // if (signInUser.message) {
    //   yield put(showAuthMessage(signInUser.message));
    // } else {
    //   localStorage.setItem("user_id", signInUser.user.uid);
    //   yield put(userSignInSuccess(signInUser.user.uid));
    // }
    if (signInUser?.status === 200) {
      localStorage.setItem("user_id", signInUser?.name || "");
      localStorage.setItem("user", JSON.stringify(signInUser));
      localStorage.setItem("token", signInUser?.accessToken);
      localStorage.setItem("refreshToken", signInUser?.refreshToken);
      localStorage.setItem(
        "selectedCompanyId",
        signInUser?.companies[0]?._id || ""
      );
      localStorage.setItem(
        "selectedCompanyName",
        signInUser?.companies[0]?.name || ""
      );

      yield put(showSuccessMessage(signInUser?.message || ""));
      // yield put(showAuthMessage(signInUser));
      yield put(userSignInSuccess(signInUser?.name));
      yield put(
        SelectedCompanyId(
          signInUser?.companies[0]?._id,
          signInUser?.companies[0]?.name
        )
      );
    } else if (signInUser?.data?.status === 400) {
      yield put(showAuthMessage(signInUser?.data?.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error?.data));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser?.data?.status === 200) {
      localStorage.removeItem("user_id");
      localStorage.clear();
      yield put(userSignOutSuccess(signOutUser));
      yield put(showAuthMessage(signOutUser?.data));
    } else {
      yield put(showAuthMessage(signOutUser?.data));
    }
  } catch (error) {
    yield put(showAuthMessage(signOutUser?.data));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
  ]);
}

// function* userLoginSaga() {
//   yield takeLatest(GET_USER_LOGIN_BY_ID_REQUEST, getAllCompanies);
//   yield takeLatest(GET_USER_LOGIN_REQUEST, getCompany);
//   yield takeLatest(ADD_USER_LOGIN_REQUEST, addCompany);
//   yield takeLatest(UPDATE_USER_LOGIN_REQUEST, updateCompany);
//   yield takeLatest(DELETE_USER_LOGIN_REQUEST, deleteCompany);
// }

// export default userLoginSaga;
