export const forgotOtp = (formValues) => {
  const errors = {};
  if (
    !formValues.otp1 ||
    !formValues.otp2 ||
    !formValues.otp3 ||
    !formValues.otp4 ||
    !formValues.otp5 ||
    !formValues.otp6
  )
    errors.otp = "Please enter OTP";
  // if (!formValues.password || formValues.password === "")
  //   errors.password = "Please enter valid OTP";
  return errors;
};
