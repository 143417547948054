import React, { useEffect, useState } from "react";
import { Avatar, Button, message, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { BaseUrl } from "../../appRedux/API/axios";
import { getProfileAPI } from "../../appRedux/API/Users";
import { LogOutApi } from "../../appRedux/API/Auth";
import "./style.css";
import { userSignInSuccess } from "../../appRedux/actions";
import { useDispatch } from "react-redux";

const UserInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState();

  const handleLogout = async () => {
    const signOutUser = await LogOutApi();
    if (signOutUser?.data?.status === 200) {
      localStorage.clear();
      dispatch(userSignInSuccess(""));
      history.push(`/signin`);
      message.success(signOutUser?.data?.message);
    } else {
      message.error(signOutUser?.data?.message);
    }
    localStorage.clear();
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          history.push(`/user/user-profile/:id`);
        }}
      >
        My Account
      </li>
      {/* <li>Connections</li> */}
      <li onClick={() => handleLogout()}>Logout</li>
      {/* <li onClick={() => setColor("#f8f9ff")}>
        <Popover
          title=""
          trigger="click"
          // open={open}
          // onOpenChange={handleOpenChange}
          content={
            <ul
              style={{ listStyle: "none", padding: "0px", marginBottom: "0px" }}
            >
              <li>Download PDF</li>
              <hr style={{ margin: "2px" }} />
              <li>Download xlsx</li>
            </ul>
          }
          placement={"leftTop"}
        >
          <div style={{ background: color }}>Download Report</div>
        </Popover>
      </li> */}
    </ul>
  );

  const fetchUserById = async () => {
    const response = await getProfileAPI();
    localStorage.setItem(
      "defaultAlertTime",
      response?.data?.data?.defaultAlertTime
    );
    setUserData(response?.data?.data);
  };

  useEffect(() => {
    fetchUserById();
  }, []);

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      {/* <div className="headerDiv">
        <img
          src={
            userData?.profilePic
              ? `${BaseUrl}/${userData?.profilePic}`
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
          }
          alt={
            userData?.profilePic
              ? `${BaseUrl}/${userData?.profilePic}`
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
          }
          className="headerProfileimage gx-cursor-pointer"
        />
      </div> */}
      <Avatar
        src={
          userData?.profilePic
            ? `${BaseUrl}/${userData?.profilePic}`
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
        }
        className="gx-avatar gx-pointer"
        alt=""
      />
    </Popover>
  );
};

export default UserInfo;
